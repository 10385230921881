import React from "react";
import "./space-type-grouped-pin.scss";
import { ProductPin } from "shared/icons";

interface GroupedPinProps {
  clusterIndex: number;
  clusterLength: number;
  dataset: number[][];
}

const SpaceTypeGroupedPin: React.FC<GroupedPinProps> = ({ clusterLength }) => {
  return (
    <div className="space-type-grouped-pin">
      <div className="space-type-grouped-pin__count">{clusterLength}</div>
      <ProductPin className="icon-pin" />
    </div>
  );
};

export default SpaceTypeGroupedPin;
