import React from "react";
import { SpaceTypeConfiguration } from "models/SpaceType/spaceTypeConfiguration.model";
import { Modal } from "antd";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";

interface DeleteConfigModalProps {
  open: boolean;
  config: SpaceTypeConfiguration;
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteConfigModal = ({
  open,
  config,
  onDelete,
  onCancel,
}: DeleteConfigModalProps) => {
  const getName = () => {
    if (config?.spaceSubType?.id) return config?.spaceSubType?.name;
    if (config?.spaceTypeCategory?.id) return config?.spaceTypeCategory?.name;
    return "";
  };

  const getType = () => {
    if (config?.spaceSubType?.id) return "Space Sub Type";
    if (config?.spaceTypeCategory?.id) return "Space Type";
    return "";
  };

  const handleDelete = async () => {
    await onDelete();
  };

  const getWarningMessage = () => {
    if (config?.spaceSubType?.id) {
      return "This will delete the space sub type and all associated space types. This action cannot be undone.";
    }
    if (config?.spaceTypeCategory?.id) {
      return "This will delete the space type and all its sub types and associated space types. This action cannot be undone.";
    }
    return "";
  };

  return (
    <Modal
      centered
      className="delete-config-modal"
      closable={false}
      footer={null}
      open={open}
      title={`Delete ${getType()}`}
    >
      <div className="delete-config-modal__content">
        <div className="delete-config-modal__content__text">
          <p>Are you sure you want to delete {getName()}?</p>
          <p className="delete-config-modal__content__warning">
            {getWarningMessage()}
          </p>
        </div>
        <div className="delete-config-modal__content__footer">
          <Button onClick={onCancel} type={ButtonType.DEFAULT}>
            cancel
          </Button>
          <Button
            className="text-white tw-ml-4"
            onClick={handleDelete}
            type={ButtonType.PRIMARY}
          >
            confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfigModal;
