import React from "react";
import { Modal } from "antd";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import Dropdown from "shared/components/DropdownField";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { generateDropdownOptions } from "shared/utils/generateDropdownOptions";
import { useSpaceTypes } from "shared/hooks/useSpaceTypes";
import "./AddSpaceModal.scss";
import { useSelectedZone } from "context/SelectedZoneContext";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useCreateFromSpaceType } from "shared/hooks/useSpaceTypes";
import { useParams } from "react-router-dom";
import useRedirect from "shared/hooks/useRedirect";

interface AddSpaceModalProps {
  open: boolean;
  onCancel: () => void;
  onCreateNew: () => void;
  categories?: SpaceTypeCategory[];
  selectedCategory?: string;
  selectedSubCategory?: string;
  selectedSpaceType?: string;
  onCategoryChange: (value: string) => void;
  onSubCategoryChange: (value: string) => void;
  onSpaceTypeChange: (value: string) => void;
}

const AddSpaceModal = ({
  open,
  onCancel,
  onCreateNew,
  categories,
  selectedCategory,
  selectedSubCategory,
  selectedSpaceType,
  onCategoryChange,
  onSubCategoryChange,
  onSpaceTypeChange,
}: AddSpaceModalProps) => {
  const createFromSpaceType = useCreateFromSpaceType();

  const selectedCategoryData = categories?.find(
    (cat) => cat.id === selectedCategory
  );

  const { data: spaceTypes, isLoading: spaceTypesLoading } = useSpaceTypes(
    selectedCategory,
    selectedSubCategory
  );

  const { selectedZoneId } = useSelectedZone();
  const { selectedPlanId, plan, updatePlanDetails } = useSelectedPlan();

  const handleCreateFromSpaceType = async () => {
    if (!selectedSpaceType || !selectedZoneId || !selectedPlanId) {
      return;
    }

    try {
      const newSpace = await createFromSpaceType.mutateAsync({
        spaceTypeId: selectedSpaceType,
        zoneId: selectedZoneId,
        planId: selectedPlanId,
      });

      // Update plan context with new space
      if (plan && newSpace) {
        const planZones = plan.zones;
        if (planZones) {
          const updatedZone = planZones.find(
            (zone) => zone.id === selectedZoneId
          );
          if (updatedZone && updatedZone.spaces) {
            updatedZone.spaces.push(newSpace);
          }
        }
        updatePlanDetails?.({ ...plan });
      }

      // Close modal and redirect to new space
      onCancel();
      // redirectToSpaceView(prototypeId, planId, zoneId, newSpace.id);
    } catch (error) {
      // Error handling is done in the mutation
      return;
    }
  };

  return (
    <Modal
      centered
      className="add-space-modal"
      closable={false}
      footer={null}
      open={open}
      title="Add Space"
    >
      <div className="tw-flex tw-flex-col tw-gap-6 tw-p-4">
        <Button
          type={ButtonType.PRIMARY}
          onClick={onCreateNew}
          className="tw-w-full"
        >
          Create New Space
        </Button>

        <div className="tw-flex tw-flex-col tw-gap-4">
          <p className="tw-text-gray-600">
            Or select from existing space types:
          </p>
          <div className="tw-flex tw-flex-col tw-gap-4">
            <Dropdown
              placeholder="Select Category"
              options={generateDropdownOptions("name", "id", categories || [])}
              onChange={onCategoryChange}
              value={selectedCategory}
            />
            <Dropdown
              placeholder="Select Sub Category"
              options={generateDropdownOptions(
                "name",
                "id",
                selectedCategoryData?.subCategories || []
              )}
              onChange={onSubCategoryChange}
              value={selectedSubCategory}
              disabled={!selectedCategory}
            />
            <Dropdown
              placeholder="Select Space Type"
              options={generateDropdownOptions("title", "id", spaceTypes || [])}
              onChange={onSpaceTypeChange}
              value={selectedSpaceType}
              disabled={!selectedSubCategory}
              loading={spaceTypesLoading}
            />
          </div>
        </div>

        {selectedSpaceType && (
          <Button
            type={ButtonType.PRIMARY}
            onClick={handleCreateFromSpaceType}
            className="tw-w-full"
            loading={createFromSpaceType.isLoading}
          >
            Create From Space Type
          </Button>
        )}

        <div className="tw-flex tw-justify-end tw-gap-4">
          <Button type={ButtonType.DEFAULT} onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddSpaceModal;
