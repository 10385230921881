import React, { FC, useState } from "react";
import { Edit } from "shared/icons";
import { DiagramView as DiagramViewEnum } from "enums/diagramView";
import useRedirect from "shared/hooks/useRedirect";
import { useParams } from "react-router-dom";
import { SpaceTypeGetData } from "types/spaceTypes";
import { CONSTANTS } from "shared/constants";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { userHasAccess } from "utils";
import UploadInput from "shared/components/UploadInput";
import { Attachment } from "models/Attachment/attachment.model";
import Label from "shared/components/Label";
import axiosInstance from "interceptor/axiosInstance";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import "./dualViewRightSide.scss";
import UploadedImage from "shared/components/UploadedImage";
import { Popover, Skeleton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import SpaceTypeDiagramView from "shared/components/SpaceTypeDiagramView";
import { useSpaceTypeSelectedLayer } from "context/SpaceTypeSelectedLayerContext";
import { useSelectedSpaceTypeProduct } from "context/SelectedSpaceTypeProductContext";
import SpaceTypeLayerVariantView from "shared/components/SpaceTypeCombined/SpaceTypeLayerVariantView";

interface DualViewRightSideType {
  viewDetails?: SpaceTypeGetData;
  viewType: DiagramViewEnum.SPACE;
}

const DualViewRightSide: FC<DualViewRightSideType> = ({ viewDetails }) => {
  const { redirectToUpdateSpaceType } = useRedirect();
  const { spaceTypeId } = useParams();
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [diagrams, setDiagrams] = useState<Attachment[]>(
    viewDetails?.gallery || []
  );
  const { selectedLayerId, updateSelectedLayerId } =
    useSpaceTypeSelectedLayer();
  const { isViewingProduct } = useSelectedSpaceTypeProduct();

  const selectedLayer = viewDetails?.layers?.find(
    (layer) => layer.id === selectedLayerId
  );

  const renderPopoverContent = () => {
    return (
      <Popover content="Close">
        <div
          className="diagram-view__image__action dual-view-icons__icon-gp__icon"
          onClick={() => {
            updateSelectedLayerId?.(undefined);
          }}
        >
          <CloseOutlined className="icon" />
        </div>
      </Popover>
    );
  };

  const handleImageUpload = async (attachments: Attachment[]) => {
    if (attachments?.[0]?.id) {
      try {
        const newAttachment = attachments[0];
        await axiosInstance.patch(
          `${ApiRoutes.SPACE_TYPE_GALLERY.replace(
            ":spaceTypeId",
            spaceTypeId ?? ""
          )}`,
          {
            space_type: {
              gallery: [{ id: newAttachment.id }],
            },
            action: "add",
          }
        );
        setDiagrams((prev) => [...prev, newAttachment]);
      } catch (error) {
        console.error("Error uploading space type diagram:", error);
      }
    }
  };

  const handleDeleteImage = async (diagramToDelete: Attachment) => {
    if (diagramToDelete?.id && spaceTypeId) {
      try {
        await axiosInstance.patch(
          `${ApiRoutes.SPACE_TYPE_GALLERY.replace(
            ":spaceTypeId",
            spaceTypeId
          )}`,
          {
            space_type: {
              gallery: [{ id: diagramToDelete.id }],
            },
            action: "remove",
          }
        );
        setDiagrams((prev) => prev.filter((d) => d.id !== diagramToDelete.id));
      } catch (error) {
        console.error("Error deleting space type diagram:", error);
      }
    }
  };

  return (
    <div
      className={`dual-view__right-container tw-overflow-y-auto ${
        selectedLayerId ? "diagram-view-expand" : ""
      }`}
    >
      {isViewingProduct ? (
        <SpaceTypeLayerVariantView />
      ) : selectedLayerId ? (
        selectedLayer?.diagram?.url ? (
          <>
            {selectedLayer.title && (
              <div className="diagram-view__text-overlay tw-px-4 tw-py-2 tw-text-2xl">
                {selectedLayer.title}
              </div>
            )}
            <SpaceTypeDiagramView
              closeIcon={renderPopoverContent()}
              imgUrl={selectedLayer.diagram.url}
            />
          </>
        ) : (
          <Skeleton.Image
            className="dual-view__right-container__image-skeleton"
            active
          />
        )
      ) : viewDetails?.id ? (
        <>
          <div className="dual-view__right-container__details">
            <div className="dual-view__right-container__details__header">
              <div className="top-level-heading">{viewDetails?.title}</div>
              {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                <div className="dual-view-icons__icon-gp">
                  <div
                    onClick={() => {
                      spaceTypeId && redirectToUpdateSpaceType(spaceTypeId);
                    }}
                  >
                    <Edit className="icon--edit tw-w-12 tw-h-12" />
                  </div>
                </div>
              )}
            </div>
            <div className="dual-view__right-container__details__description scrollbar-visible">
              <div className="description--text multiline">
                <span>
                  {showFullDesc
                    ? viewDetails?.description
                    : viewDetails?.description?.substring(
                        0,
                        CONSTANTS.MAX_DESC_LENGTH_COLLAPSED
                      )}
                  {(viewDetails?.description &&
                    viewDetails?.description?.length <
                      CONSTANTS.MAX_DESC_LENGTH_COLLAPSED) ||
                  showFullDesc
                    ? ""
                    : "..."}
                </span>
                <span
                  onClick={() => setShowFullDesc(!showFullDesc)}
                  className={`dual-view__right-container__details__description--action ${
                    viewDetails?.description &&
                    viewDetails?.description?.length <
                      CONSTANTS.MAX_DESC_LENGTH_COLLAPSED
                      ? "display--none"
                      : ""
                  }`}
                >
                  {showFullDesc ? "See Less" : "See More"}
                </span>
              </div>
            </div>
            <hr />
            <div className="plan-form__description__diagram">
              <Label label="Space Type Diagrams" />
              <div className="space-type-gallery">
                <div className="space-type-gallery__item">
                  <UploadInput
                    title="Upload Image"
                    note="Supported formats: jpg, png & jpeg and < 50MB"
                    hideLoaderOnComplete
                    primaryTheme
                    hideStatusBar={false}
                    options={{
                      restrictions: {
                        allowedFileTypes: [".jpg", ".jpeg", ".png"],
                        maxFileSize: 50 * 1024 * 1024,
                      },
                    }}
                    onAttachmentChange={handleImageUpload}
                  />
                </div>
                {diagrams.map((diagram) => (
                  <div key={diagram.id} className="space-type-gallery__item">
                    <UploadedImage
                      expandable
                      hideRemoveTextOnOverlay
                      src={diagram.url}
                      onDelete={() => handleDeleteImage(diagram)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DualViewRightSide;
