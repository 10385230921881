import React from "react";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { Popover, Tooltip } from "antd";
import useToggle from "shared/hooks/useToggle";
import { Add, Edit } from "shared/icons";
import AddItem from "../AddItem";

import "./configItemForm.scss";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { SpaceTypeSubCategory } from "models/SpaceType/spaceTypeSubCategory.model";

interface ConfigItemFormProps<T> {
  title: string;
  item: T;
  onSubmit: (value: T) => Promise<void>;
}

const ConfigItemForm = <
  T extends
    | Category
    | SubCategory
    | Type
    | SpaceTypeCategory
    | SpaceTypeSubCategory
>({
  item,
  title,
  onSubmit,
}: ConfigItemFormProps<T>) => {
  const isEdit = item?.id;

  const { isToggled: isPopoverVisible, setToggle: setPopoverVisible } =
    useToggle();

  const { isToggled: isTooltipVisible, setToggle: setTooltipVisible } =
    useToggle();

  const handlePopoverChange = (open: boolean) => {
    if (!open) setTooltipVisible(open);

    setPopoverVisible(open);
  };

  const handleSubmit = async (value: T) => {
    handlePopoverChange(false);
    await onSubmit(value);
  };

  console.log({
    isPopoverVisible,
    isTooltipVisible,
  });

  return (
    <div className="config-item-form">
      <Tooltip
        onOpenChange={setTooltipVisible}
        open={!isPopoverVisible && isTooltipVisible}
        placement={isEdit ? "top" : "right"}
        title={`${isEdit ? "Edit" : "Add"} ${title}`}
        zIndex={10}
      >
        <Popover
          content={
            <AddItem
              item={item}
              onCancel={() => handlePopoverChange(false)}
              onSubmit={handleSubmit}
            />
          }
          onOpenChange={handlePopoverChange}
          open={isPopoverVisible}
          placement="bottomRight"
          trigger="click"
          zIndex={10}
        >
          {isEdit ? (
            <Edit className="icon icon--edit tw-w-8 tw-h-8" />
          ) : (
            <Add className="icon-primary config-item-form__add" />
          )}
        </Popover>
      </Tooltip>
    </div>
  );
};

export default ConfigItemForm;
