import { useMutation, useQuery } from "@tanstack/react-query";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance from "interceptor/axiosInstance";
import { Space } from "models/Space/space.model";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { QUERY_KEYS } from "shared/constants/queries";
import { SpaceTypeGetData } from "types/spaceTypes";
import { generatePath } from "react-router-dom";

export const useSpaceTypes = (categoryId?: string, subCategoryId?: string) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.SPACE_TYPES,
      {
        categoryId,
        subCategoryId,
      },
    ],
    queryFn: async () => {
      const { data } = await axiosInstance.get(ApiRoutes.SPACE_TYPES, {
        params: {
          categoryId,
          subCategoryId,
        },
      });
      return data.space_types as SpaceTypeGetData[];
    },
    enabled: !!categoryId && !!subCategoryId,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

interface CreateFromSpaceTypeParams {
  spaceTypeId: string;
  zoneId: string;
  planId: string;
}

export const useCreateFromSpaceType = () => {
  return useMutation({
    mutationFn: async ({
      spaceTypeId,
      zoneId,
      planId,
    }: CreateFromSpaceTypeParams) => {
      try {
        const { data } = await axiosInstance.post(
          ApiRoutes.CREATE_FROM_SPACE_TYPE,
          {
            spaceTypeId,
            zoneId,
            planId,
          }
        );

        const space = deserialize(Space, data.space);

        Notification({
          message: "Space created successfully from space type",
          type: NotificationTypes.SUCCESS,
        });

        return space;
      } catch (err) {
        Notification({
          message:
            (err as Error)?.message || "Failed to create space from space type",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });
};

export const useDeleteSpaceType = () => {
  return useMutation({
    mutationFn: async (spaceTypeId: string) => {
      try {
        await axiosInstance.delete(
          generatePath(ApiRoutes.SPACE_TYPE, { spaceTypeId })
        );
        Notification({
          message: "Space type deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
        return true;
      } catch (err) {
        Notification({
          message: (err as Error)?.message || "Failed to delete space type",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });
};
