import { alias, identifier, object, serializable } from "serializr";
import { SpaceTypeCategory } from "./spaceTypeCategory.model";

export class SpaceSubType {
  @serializable(identifier())
  id?: string;

  @serializable
  name?: string;

  @serializable
  description?: string;

  @serializable(object(SpaceTypeCategory))
  spaceTypeCategory?: SpaceTypeCategory;

  @serializable(alias("created_at"))
  createdAt?: string;

  @serializable(alias("updated_at"))
  updatedAt?: string;
}
