import React from "react";
import { useQuery } from "@tanstack/react-query";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { SpaceTypeSubCategory } from "models/SpaceType/spaceTypeSubCategory.model";
import axiosInstance from "interceptor/axiosInstance";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { QUERY_KEYS } from "shared/constants/queries";
import { Skeleton } from "antd";
import { SpaceTypeGetData } from "types/spaceTypes";
import { generatePath, useNavigate } from "react-router-dom";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { ButtonType } from "enums/buttonType.enum";
import Button from "shared/components/Button";
const DESCRIPTION_MAX_LENGTH = 150;

interface SpaceTypeGridProps {
  category?: SpaceTypeCategory;
  subCategory?: SpaceTypeSubCategory;
}

const SpaceTypeGrid: React.FC<SpaceTypeGridProps> = ({
  category,
  subCategory,
}) => {
  const navigate = useNavigate();

  const { data: spaceTypes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.SPACE_TYPES, category?.id, subCategory?.id],
    queryFn: async () => {
      const { data } = await axiosInstance.get(ApiRoutes.SPACE_TYPES, {
        params: {
          categoryId: category?.id,
          subCategoryId: subCategory?.id,
        },
      });
      return data.space_types as SpaceTypeGetData[];
    },
    enabled: !!category?.id && !!subCategory?.id,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const truncateDescription = (description: string) => {
    if (description.length <= DESCRIPTION_MAX_LENGTH) return description;
    return `${description.substring(0, DESCRIPTION_MAX_LENGTH)}...`;
  };

  const handleSpaceTypeClick = (id: string) => {
    navigate(
      generatePath(NavigationRoutes.VIEW_SPACE_TYPE, { spaceTypeId: id })
    );
  };

  const handleAddSpaceType = () => {
    navigate(
      `${NavigationRoutes.SPACE_TYPE_FORM}?category=${category?.id}&subCategory=${subCategory?.id}`
    );
  };

  return (
    <div className="tw-p-8">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-text-3xl tw-font-medium tw-mb-0">
          {category?.name} - {subCategory?.name}
        </h2>
        <Button
          type={ButtonType.PRIMARY}
          onClick={handleAddSpaceType}
          className="tw-rounded-none tw-h-20"
        >
          Add Space Type
        </Button>
      </div>
      <Skeleton loading={isLoading}>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-6">
          {spaceTypes?.map((spaceType) => (
            <div
              key={spaceType.id}
              className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-p-6 tw-border tw-border-gray-200 hover:tw-shadow-md tw-transition-shadow tw-duration-200 tw-cursor-pointer"
              onClick={() => handleSpaceTypeClick(spaceType.id)}
            >
              <h3 className="tw-text-2xl tw-font-medium tw-mb-2 tw-text-gray-900">
                {spaceType.title}
              </h3>
              <p className="tw-text-gray-600 tw-text-base">
                {truncateDescription(spaceType.description || "")}
              </p>
              <div className="tw-mt-4 tw-text-sm tw-text-gray-500">
                <div>Category: {spaceType.category?.name}</div>
                <div>Sub Category: {spaceType.subCategory?.name}</div>
              </div>
            </div>
          ))}
        </div>
      </Skeleton>
    </div>
  );
};

export default SpaceTypeGrid;
