import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { QUERY_KEYS } from "shared/constants/queries";
import SpaceTypeDualViewContainer from "shared/components/SpaceTypeDualViewContainer";
import { SpaceTypeGetData } from "types/spaceTypes";

const SpaceTypeDetailView = () => {
  const { spaceTypeId } = useParams();

  const { data: spaceType, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.SPACE_TYPE, spaceTypeId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `${ApiRoutes.SPACE_TYPES}/${spaceTypeId}`
      );
      return data?.space_type as SpaceTypeGetData;
    },
    enabled: !!spaceTypeId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return spaceType ? (
    <SpaceTypeDualViewContainer loading={isLoading} viewDetails={spaceType} />
  ) : (
    <div>Space Type not found</div>
  );
};

export default SpaceTypeDetailView;
