import React, { FC, useRef } from "react";
import { Back } from "shared/icons";
import ExpandableCard from "../ExpandableCard";
import useRedirect from "shared/hooks/useRedirect";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { PrototypeWithPlans } from "models/PrototypeWithPlans/prototypeWithPlans";
import { Space } from "models/Space/space.model";
import { Layer } from "models/Layer/layer.model";
import { Plan } from "models/Plans/plans.model";
import { useParams } from "react-router-dom";
import Breadcrumb from "shared/components/Breadcrumb";
import { DiagramView } from "enums/diagramView";
import Spinner from "shared/components/Spinner";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { getSubViewType } from "shared/utils/parser";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";

interface DualViewLeftSideType {
  viewType: DiagramView;
  breadCrumbItemList?: ItemType[];
  loading: boolean;
  viewDetails: PrototypeWithPlans | Space;
  subViewList: Plan[] | Layer[] | undefined;
  categories?: SpaceTypeCategory[];
}

const DualViewLeftSide: FC<DualViewLeftSideType> = ({
  viewType,
  breadCrumbItemList,
  viewDetails,
  loading,
  subViewList,
  categories,
}) => {
  const subviewType = getSubViewType(viewType);

  const { redirectToAddPlan, redirectToPrototypes, redirectToAddLayer } =
    useRedirect();
  const { prototypeId, planId, spaceId } = useParams();
  return (
    <div className="dual-view__left-container">
      {viewType === DiagramView.PROTOTYPE ? (
        <div
          className="dual-view__left-container__back-btn"
          onClick={redirectToPrototypes}
        >
          <Back className="icon--back tw-w-16 tw-h-4" />
          <span>Back</span>
        </div>
      ) : (
        <Breadcrumb items={breadCrumbItemList} size="sm" truncate />
      )}
      <div className="top-level-heading ">{viewDetails?.title}</div>
      <Spinner loading={loading}>
        <div className="sub-view-list scrollbar-visible">
          {subViewList?.map((subView) => (
            <ExpandableCard
              subViewType={subviewType}
              subView={subView}
              viewId={viewDetails?.id}
              key={subView?.id}
              categories={categories}
            />
          ))}
        </div>
        <div className="dual-view__left-container__add-sub-view">
          {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
            <div
              className="dual-view__left-container__add-sub-view__wrapper"
              onClick={() =>
                viewType === DiagramView.PROTOTYPE
                  ? prototypeId && redirectToAddPlan(prototypeId)
                  : prototypeId &&
                    planId &&
                    spaceId &&
                    redirectToAddLayer(prototypeId, planId, spaceId)
              }
            >
              <div className="pd-8">
                &#43;&nbsp;&nbsp;Add{" "}
                {`${viewType === DiagramView.PROTOTYPE ? "Plan" : "Layer"}`}
              </div>
            </div>
          )}
        </div>
      </Spinner>
    </div>
  );
};

export default DualViewLeftSide;
