import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { Modal, Button } from "antd";
import { ButtonType } from "enums/buttonType.enum";
import "react-image-crop/dist/ReactCrop.css";
import "./imageCropper.scss";
import { UppyFile } from "@uppy/core";
import { generateFileFromURL } from "shared/utils/generateFileFromURL";
import { isEqual } from "lodash";

interface ImageCropperProps {
  visible: boolean;
  imageUrl: string;
  onCancel: () => void;
  onCropComplete: (file: UppyFile) => Promise<void>;
  fileName?: string;
  onExit?: () => void;
  aspectRatio?: number;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  visible,
  imageUrl,
  onCancel,
  onCropComplete,
  fileName = "cropped-image.jpg",
  onExit,
  aspectRatio,
}) => {
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
  }>();
  const imageRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();

  // Memoize default crop calculation
  const defaultCrop = useMemo(() => {
    if (!imageSize) return;

    if (!aspectRatio) {
      return {
        unit: "%",
        width: 100,
        height: 100,
        x: 0,
        y: 0,
      } as Crop;
    }

    const { width, height } = imageSize;
    const isWidthShorter = width < height;

    if (isWidthShorter) {
      const cropWidth = 100;
      const cropHeight = (width / height) * 100;
      const y = (100 - cropHeight) / 2;

      return {
        unit: "%",
        width: cropWidth,
        height: cropHeight,
        x: 0,
        y,
      } as Crop;
    }

    const cropHeight = 100;
    const cropWidth = (height / width) * 100;
    const x = (100 - cropWidth) / 2;

    return {
      unit: "%",
      width: cropWidth,
      height: cropHeight,
      x,
      y: 0,
    } as Crop;
  }, [aspectRatio, imageSize]);

  // Memoize image load handler
  const handleImageLoad = useCallback(() => {
    if (imageRef.current) {
      const img = imageRef.current;
      setImageSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    }
  }, []);

  // Set up image load listener
  useEffect(() => {
    const img = imageRef.current;
    if (!img) return;

    if (img.complete) {
      handleImageLoad();
    } else {
      img.addEventListener("load", handleImageLoad);
    }

    return () => {
      img.removeEventListener("load", handleImageLoad);
    };
  }, [handleImageLoad]);

  // Update crop when defaultCrop changes
  useEffect(() => {
    if (defaultCrop) {
      setCrop(defaultCrop);
    }
  }, [defaultCrop]);

  // Memoize crop handler
  const handleCropChange = useCallback((newCrop: Crop) => {
    setCrop(newCrop);
  }, []);

  // Memoize getCroppedImg function
  const getCroppedImg = useCallback(async () => {
    const image = imageRef.current;
    if (!image) return;

    if (isEqual(crop, defaultCrop)) {
      const originalFile = await generateFileFromURL(imageUrl, fileName);
      return createUppyFile(originalFile, fileName);
    }

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.ceil((crop?.width || 0) * scaleX);
    canvas.height = Math.ceil((crop?.height || 0) * scaleY);

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.drawImage(
      image,
      (crop?.x || 0) * scaleX,
      (crop?.y || 0) * scaleY,
      (crop?.width || 0) * scaleX,
      (crop?.height || 0) * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const croppedFile = await generateFileFromURL(
      canvas.toDataURL("image/jpeg", 1),
      fileName
    );

    return createUppyFile(croppedFile, fileName);
  }, [crop, defaultCrop, imageUrl, fileName]);

  // Memoize crop complete handler
  const handleCropComplete = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const croppedImage = await getCroppedImg();
      if (croppedImage) {
        await onCropComplete(croppedImage);
        onCancel();
      }
    },
    [getCroppedImg, onCropComplete, onCancel]
  );

  // Memoize cancel handler
  const handleCancel = useCallback(() => {
    onCancel();
    onExit?.();
  }, [onCancel, onExit]);

  return (
    <Modal
      open={visible}
      title="Crop Image"
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          type={ButtonType.DEFAULT}
          className="tw-rounded-none tw-h-16 tw-border-[#D1D1D6]"
          onClick={handleCancel}
        >
          Discard
        </Button>,
        <Button
          key="crop"
          type={ButtonType.PRIMARY}
          className="tw-rounded-none tw-h-16"
          onClick={handleCropComplete}
        >
          Create
        </Button>,
      ]}
    >
      <ReactCrop
        crop={crop}
        onChange={handleCropChange}
        aspect={aspectRatio}
        className="image-cropper"
      >
        <img
          ref={imageRef}
          src={imageUrl}
          alt="crop-preview"
          crossOrigin="anonymous"
        />
      </ReactCrop>
    </Modal>
  );
};

// Helper function to create UppyFile object
const createUppyFile = (file: File, fileName: string): UppyFile => ({
  data: file,
  name: fileName,
  type: file.type,
  size: file.size,
  preview: URL.createObjectURL(file),
  id: Math.random().toString(36).substring(2),
  progress: {
    percentage: 0,
    bytesUploaded: 0,
    bytesTotal: file.size,
    uploadStarted: 0,
    uploadComplete: true,
  },
  meta: { name: fileName },
  remote: { host: "", url: "" },
  source: "local",
  isRemote: false,
  extension: file.type.split("/")[1],
});

export default React.memo(ImageCropper);
