import { serializable } from "serializr";

export class SpaceTypeSubCategory {
  @serializable
  id?: string;

  @serializable
  _id?: string;

  @serializable
  name?: string;
}
