import React from "react";
import { RouterProps } from "shared/types/route.type";
import { AppRoutes, NavigationRoutes } from "./routeConstants/appRoutes";
import AppComponents from "views/AppComponents";
import AuthWrapper from "views/Auth/AuthWrapper";
import Home from "views/Home";
import Products from "views/Products";
import isAuthenticated from "shared/components/HOC/requireAuth";
import ViewProduct from "views/ViewProduct";
import Configurations from "views/Configurations";
import Prototypes from "views/Prototypes";
import PrototypeForm from "views/PrototypeForm";
import PlanForm from "views/PlanForm";
import PlanView from "views/PlanView";
import SpaceForm from "views/SpaceForm";
import ProductForm from "views/ProductForm";
import PrototypeViewCombined from "views/Prototypes/PrototypeGrid/PrototypeViewCombined";
import PicklistToggleState from "context/PicklistToggleContext";
import PicklistDrawer from "shared/components/PicklistDrawer";
import SpaceViewCombined from "views/Prototypes/SpaceViewCombined";
import LayerForm from "views/LayerForm";
import PickLists from "views/Picklist";
import ProductsList from "views/ProductsList";
import UserManagement from "views/UserManagement";
import UserForm from "views/UserForm";
import UserRolesState from "context/UserRolesContext";
import PrototypeGrid from "views/Prototypes/PrototypeGrid";
import NoAccess from "views/NoAccess";
import NotificationPage from "views/Notifications";
import ZoneViewCombined from "views/Prototypes/ZoneViewCombined";
import ZoneForm from "views/ZoneForm";
import SpaceTypes from "views/SpaceTypes";
import SpaceTypeForm from "views/SpaceTypeForm";
import SpaceTypeDetailView from "views/SpaceTypes/SpaceTypeDetailView";

const routes: RouterProps[] = [
  { path: AppRoutes.AUTH, component: <AuthWrapper /> },
  {
    path: AppRoutes.HOME,
    component: isAuthenticated(<Home />),
    children: [
      {
        path: NavigationRoutes.PROTOTYPE,
        component: <Prototypes />,
        children: [
          {
            path: "/prototypes",
            component: <PrototypeGrid />,
          },
          {
            path: NavigationRoutes.VIEW_PROTOTYPE,
            component: <PrototypeViewCombined />,
          },
          {
            path: NavigationRoutes.VIEW_SPACE,
            component: <SpaceViewCombined />,
          },
          {
            path: NavigationRoutes.VIEW_ZONE,
            component: <ZoneViewCombined />,
          },
          {
            path: NavigationRoutes.ADD_PROTOTYPE,
            component: <PrototypeForm />,
          },
          {
            path: NavigationRoutes.ADD_PROTOTYPE,
            component: <PrototypeForm />,
          },
          {
            path: AppRoutes.UPDATE_PROTOTYPE,
            component: <PrototypeForm />,
          },
          {
            path: AppRoutes.ADD_PLAN,
            component: <PlanForm />,
          },
          {
            path: AppRoutes.EDIT_PLAN,
            component: <PlanForm />,
          },
          {
            path: AppRoutes.ADD_LAYER,
            component: <LayerForm />,
          },
          {
            path: AppRoutes.EDIT_LAYER,
            component: <LayerForm />,
          },
          {
            path: AppRoutes.ADD_ZONE,
            component: <ZoneForm />,
          },
          {
            path: AppRoutes.EDIT_ZONE,
            component: <ZoneForm />,
          },
          {
            path: AppRoutes.ADD_SPACE,
            component: <SpaceForm />,
          },
          {
            path: AppRoutes.EDIT_SPACE,
            component: <SpaceForm />,
          },
          {
            path: AppRoutes.PRODUCT_LIST,
            component: <ProductsList />,
          },
          {
            path: AppRoutes.PRODUCT_REPLACE,
            component: <ProductsList />,
          },
        ],
      },
      {
        path: NavigationRoutes.PRODUCTS,
        component: <Products />,
      },
      {
        path: NavigationRoutes.SPACE_TYPES,
        component: <SpaceTypes />,
      },
      {
        path: NavigationRoutes.SPACE_TYPE_FORM,
        component: <SpaceTypeForm />,
      },
      {
        path: `${NavigationRoutes.SPACE_TYPE_FORM}/:spaceTypeId`,
        component: <SpaceTypeForm />,
      },
      {
        path: NavigationRoutes.SPACE_TYPE_ADD_LAYER,
        component: <LayerForm />,
        children: [
          {
            path: NavigationRoutes.SPACE_TYPE_EDIT_LAYER,
            component: <LayerForm />,
          },
        ],
      },
      {
        path: NavigationRoutes.SPACE_TYPE_PRODUCT_LIST_REPLACE,
        component: <ProductsList />,
      },
      {
        path: NavigationRoutes.SPACE_TYPE_PRODUCT_LIST,
        component: <ProductsList />,
      },
      {
        path: NavigationRoutes.USERS,
        component: <UserManagement />,
      },
      {
        path: NavigationRoutes.ADD_USER,
        component: <UserForm />,
      },
      {
        path: NavigationRoutes.EDIT_USER,
        component: <UserForm />,
      },
      {
        path: AppRoutes.ADD_PRODUCT,
        component: <ProductForm />,
      },
      {
        path: AppRoutes.EDIT_PRODUCT,
        component: <ProductForm />,
      },
      {
        path: AppRoutes.VIEW_PRODUCT,
        component: (
          <PicklistToggleState>
            <PicklistDrawer />
            <ViewProduct />
          </PicklistToggleState>
        ),
      },
      {
        path: NavigationRoutes.SETTINGS,
        component: (
          <UserRolesState>
            <Configurations />
          </UserRolesState>
        ),
      },
      {
        path: AppRoutes.VIEW_PLAN,
        component: <PlanView />,
      },
      {
        path: NavigationRoutes.PICKLIST,
        component: <PickLists />,
      },
      {
        path: NavigationRoutes.NO_ACCESS,
        component: <NoAccess />,
      },
      {
        path: NavigationRoutes.NOTIFICATION,
        component: <NotificationPage />,
      },
      {
        path: NavigationRoutes.VIEW_SPACE_TYPE,
        component: <SpaceTypeDetailView />,
      },
    ],
  },
];

if (process.env.REACT_APP_UNDER_DEVELOPMENT)
  routes.push({
    path: AppRoutes.APP_COMPONENTS,
    component: <AppComponents />,
  });

export default routes;
