import React, { useState, useEffect } from "react";
import { DragDrop, FileInput } from "@uppy/react";
import { DragDropProps } from "@uppy/react/src/DragDrop";
import getUppyInstance from "shared/utils/getUppyInstance";
import { useUppyMultipartUpload } from "shared/hooks/useUppyMultipartUpload";
import { Attachment } from "models/Attachment/attachment.model";
import { useField } from "formik";
import Error from "shared/components/Error";
import { WithFormikProps } from "shared/types/formikFieldProps.type";
import { Restrictions, UppyOptions, UppyFile } from "@uppy/core";
import Label from "../Label";
import { FileInputProps } from "@uppy/react/src/FileInput";
import loaderAnimation from "assets/json/dot-loader.json";
import ImageCropper from "../ImageCropper";
import useToggle from "shared/hooks/useToggle";

import "@uppy/core/dist/style.min.css";
// import "@uppy/dashboard/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/status-bar/dist/style.min.css";
import "./uploadInput.scss";
import Lottie from "react-lottie";

interface UploadInputProps extends Partial<DragDropProps> {
  title?: string;
  onAttachmentChange?: (attachments: Attachment[]) => void;
  hideStatusBar?: boolean;
  options?: UppyOptions;
  label?: string;
  fileInputProps?: Omit<FileInputProps, "uppy">;
  validateAspectRatio?: boolean;
  primaryTheme?: boolean;
  hideLoaderOnComplete?: boolean;
  enableCropping?: boolean;
  aspectRatio?: number;
}

const defaultUppyRestrictions: Restrictions = {
  allowedFileTypes: [".jpeg", ".png", ".jpg"],
  maxNumberOfFiles: 1,
  maxFileSize: 10 * 1024 * 1024, // 10MB
};

const UploadInput = ({
  title,
  onAttachmentChange,
  width,
  height,
  // hideStatusBar,
  label,
  options: { restrictions, ...options } = {},
  fileInputProps,
  validateAspectRatio,
  primaryTheme,
  hideLoaderOnComplete,
  enableCropping = false,
  aspectRatio,
  ...props
}: UploadInputProps) => {
  const { isToggled: inProgress, setToggle: setInProgress } = useToggle(false);
  const [cropperVisible, setCropperVisible] = useState(false);
  const [imageToProcess, setImageToProcess] = useState<{
    url: string;
    file: File;
  }>();

  const [uppy] = useState(() =>
    getUppyInstance({
      restrictions: {
        ...defaultUppyRestrictions,
        ...restrictions,
      },
      ...options,
    })
  );

  // Prevent default Uppy upload for images when cropping is enabled
  useEffect(() => {
    if (enableCropping) {
      const handleBeforeUpload = () => {
        return false; // Prevent default upload when cropping is enabled
      };

      uppy.on("upload", handleBeforeUpload);
      return () => {
        uppy.off("upload", handleBeforeUpload);
      };
    }
  }, [enableCropping, uppy]);

  const handleCropComplete = async (croppedFile: UppyFile) => {
    setCropperVisible(false);
    setImageToProcess(undefined);
    uppy.addFile({
      name: croppedFile.name,
      type: croppedFile.type,
      data: croppedFile.data,
      source: "local",
      isRemote: false,
    });
  };

  const locale = {
    strings: {
      dropHereOr: title || "",
    },
  };

  uppy.on("upload-progress", () => {
    if (!inProgress) {
      setInProgress(true);
    }
  });
  uppy.on("file-added", (file: UppyFile) => {
    const fileSource = file.source;

    if (fileSource === "react:DragDrop") {
      if (!inProgress) {
        setInProgress(true);
      }

      if (enableCropping && isImageFile(file)) {
        setImageToProcess({
          url: URL.createObjectURL(file.data as File),
          file: file.data as File,
        });
        setCropperVisible(true);
        uppy.removeFile(file.id);
      }
    }
  });
  uppy.on("complete", () => {
    if (hideLoaderOnComplete) {
      setInProgress(false);
    }
  });
  uppy.on("restriction-failed", () => {
    setInProgress(false);
    uppy.cancelAll();
  });
  uppy.once("upload-error", () => {
    setInProgress(false);
    uppy.cancelAll();
  });

  useUppyMultipartUpload(uppy, onAttachmentChange, validateAspectRatio);

  const isImageFile = (file: UppyFile) => {
    return file.type?.startsWith("image/");
  };

  return (
    <div
      className={"upload-input".concat(
        primaryTheme ? " upload-input--primary" : ""
      )}
    >
      <Label label={label} />
      <div className="upload-input__wrapper">
        {inProgress && (
          <div className="attachment-upload-loader">
            <Lottie
              isClickToPauseDisabled
              options={{
                animationData: loaderAnimation,
                ...options,
              }}
              {...props}
            />
          </div>
        )}
        {fileInputProps ? (
          <FileInput uppy={uppy} {...fileInputProps} />
        ) : (
          <DragDrop
            uppy={uppy}
            width={width}
            height={height}
            locale={locale}
            {...props}
          />
        )}
      </div>
      {cropperVisible && imageToProcess && (
        <ImageCropper
          aspectRatio={aspectRatio}
          visible={cropperVisible}
          imageUrl={imageToProcess.url}
          onCancel={() => {
            setCropperVisible(false);
            setImageToProcess(undefined);
            setInProgress(false);
          }}
          onExit={() => {
            setInProgress(false);
          }}
          onCropComplete={handleCropComplete}
          fileName={imageToProcess.file.name}
        />
      )}
    </div>
  );
};

const UploadInputWithFormik = ({
  name,
  aspectRatio,
  ...props
}: WithFormikProps<UploadInputProps>) => {
  const [{ value }, { error, touched }, { setValue }] = useField<
    Attachment | Attachment[]
  >(name);

  const message = (error as Attachment)?.id;

  const handleAttachmentChange: UploadInputProps["onAttachmentChange"] = (
    attachment
  ) => {
    const isMultipleAttachment = Array.isArray(value);

    setValue(
      isMultipleAttachment ? [...value, ...attachment] : attachment?.[0]
    );
  };
  return (
    <div>
      <UploadInput
        aspectRatio={aspectRatio}
        enableCropping
        onAttachmentChange={handleAttachmentChange}
        {...props}
      />
      {message && touched && <Error message={message} />}
    </div>
  );
};

UploadInput.Formik = UploadInputWithFormik;

export default UploadInput;
