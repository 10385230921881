import { Empty, Row } from "antd";
import { ProductParams } from "models/Product/product.model";
import React, { FC, useEffect } from "react";
import "./filteredProducts.scss";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";
import ProductService from "services/ProductService/product.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import ProductCard from "./ProductCard";
import Breadcrumb from "shared/components/Breadcrumb";
import SkeletonInput from "antd/es/skeleton/Input";
import SearchAndFilter from "../SearchAndFilter";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import { Back } from "shared/icons";
import { Form, Formik } from "formik";
import InputNumber from "shared/components/InputNumber";
import Spinner from "shared/components/Spinner";
import LazyLoadedImage from "shared/components/LazyLoadedImage";

const PAGINATION_LIMIT = 20;

interface FilteredProductsType {
  filterParams: ProductParams;
  filterMeta: {
    categories: Category[];
    subCategories: SubCategory[];
    types: Type[];
  };
  filterLoading: boolean;
  params: ProductParams;
  setParams: React.Dispatch<React.SetStateAction<ProductParams>>;
  onSearch: (search: string, searchCategory: string) => void;
}
interface GenericObjType {
  id: string;
  name: string;
}
/*
    This component shows 3 views - 
    - Specific SubCategory Product Listing in Grid View
    - Specific Types Product Listing in Grid View
    - Search and Filter View
*/
const FilteredProducts: FC<FilteredProductsType> = ({
  filterParams,
  filterMeta,
  filterLoading,
  params,
  setParams,
  onSearch,
}) => {
  const navigate = useNavigate();
  const hasSpecificSubCategory =
    filterParams?.categories?.length === 1 &&
    filterParams?.subCategories?.length === 1;
  const isSpecificSubCategory =
    Object.keys(filterParams).length === 2 && hasSpecificSubCategory;
  const isSpecificTypes =
    Object.keys(filterParams).length === 3 &&
    hasSpecificSubCategory &&
    filterParams?.types?.length === 1;
  const getParamNameFromId = (
    specificParamList: GenericObjType[],
    paramType: "categories" | "subCategories" | "types"
  ) => {
    const selectedIdx = specificParamList?.findIndex(
      (param: { id: string }) => param?.id === filterParams[paramType][0]
    );
    return selectedIdx >= 0 ? specificParamList[selectedIdx]?.name : "";
  };
  const breadCrumbList =
    isSpecificSubCategory || isSpecificTypes
      ? [
          {
            title: getParamNameFromId(
              filterMeta?.categories as GenericObjType[],
              "categories"
            ),
            href: `${ApiRoutes.PRODUCTS}/#${filterParams?.categories[0]}`,
          },
          {
            title: getParamNameFromId(
              filterMeta?.subCategories as GenericObjType[],
              "subCategories"
            ),
            href: `${ApiRoutes.PRODUCTS}?${QueryString.stringify(
              {
                categories: filterParams?.categories,
                subCategories: filterParams?.subCategories,
              },
              {
                skipNulls: true,
              }
            )}`,
          },
        ]
      : [];
  if (isSpecificTypes) {
    breadCrumbList.push({
      title: getParamNameFromId(filterMeta?.types as GenericObjType[], "types"),
      href: "#",
    });
  }
  const typeList = filterParams?.subCategories?.length
    ? filterMeta?.types?.filter(
        (type) => type?.subCategory?.id === filterParams?.subCategories[0]
      )
    : [];

  const { fetchProducts, products, meta, loading } = ProductService();

  const handlePagination = (page: number) => {
    fetchProducts({ ...filterParams, page });
  };

  useEffect(() => {
    fetchProducts(filterParams);
  }, [filterParams]);

  return (
    <div
      className={`filtered-products ${
        !isSpecificSubCategory ? "margin-top-10" : ""
      }`}
    >
      {/* BreadCrumb */}
      {filterLoading ? (
        <SkeletonInput size="small" active className="breadcrumb-skeleton" />
      ) : (
        <Breadcrumb
          size="sm"
          items={breadCrumbList}
          className="filtered-products__breadcrumb"
        />
      )}
      {/* Header */}
      <div className="filtered-products__header">
        <div className="filtered-products__header__left">
          <div className="filtered-products__header__left__heading">
            {isSpecificSubCategory
              ? getParamNameFromId(
                  filterMeta?.subCategories as GenericObjType[],
                  "subCategories"
                )
              : isSpecificTypes
              ? getParamNameFromId(
                  filterMeta?.types as GenericObjType[],
                  "types"
                )
              : "Search Results"}
          </div>
          <div className="filtered-products__header__left__count">
            {meta?.totalDocs} {meta?.totalDocs === 1 ? "Product" : "Products"}
          </div>
        </div>
      </div>

      {/* Types navbar */}
      {isSpecificSubCategory && (
        <div className="types-navbar display-flex">
          {typeList?.map((type) => (
            <div
              key={type?.id}
              className="pd-r-32"
              onClick={() => {
                navigate({
                  pathname: location.pathname,
                  hash: location.hash,
                  search: QueryString.stringify(
                    {
                      categories: filterParams?.categories,
                      subCategories: filterParams?.subCategories,
                      types: [type?.id],
                    },
                    {
                      skipNulls: true,
                    }
                  ),
                });
              }}
            >
              <div>
                <LazyLoadedImage
                  loaderClassName="types-navbar__image"
                  imageClassName="types-navbar__image"
                  src={type?.image?.thumbnailUrl || type?.image?.url || ""}
                  alt={type?.name}
                  active={!!type?.image?.thumbnailUrl || !!type?.image?.url}
                />
              </div>
              <div className="types-navbar__text">{type?.name}</div>
            </div>
          ))}
        </div>
      )}
      <SearchAndFilter
        filters={filterMeta}
        params={params}
        setParams={setParams}
        onSearch={onSearch}
        search={params?.search}
        filterParams={filterParams}
        fetchProducts={fetchProducts}
      />

      {/* Product Listing */}
      <div className="products-grid">
        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
          <Spinner loading={loading}>
            {products.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <>
                {products?.map((product) => (
                  <ProductCard product={product} key={product?.id} />
                ))}
              </>
            )}
          </Spinner>
        </Row>
      </div>

      {/* Product Pagination */}
      <div className="products-pagination">
        <div className="doc-counter type-text">
          {products.length !== 0 ? (
            <>
              Showing{" "}
              {(meta?.page || 1) * PAGINATION_LIMIT - PAGINATION_LIMIT + 1}-
              {(meta?.page || 1) * PAGINATION_LIMIT <= (meta?.totalDocs || 1)
                ? (meta?.page || 1) * PAGINATION_LIMIT
                : meta?.totalDocs}{" "}
              of {meta?.totalDocs}
            </>
          ) : (
            <>Showing 0 of 0</>
          )}
        </div>
        <div className="page-action type-button">
          <Button
            className="back-button"
            type={ButtonType.DEFAULT}
            disabled={meta?.prevPage === null}
            onClick={() => {
              handlePagination(meta?.prevPage ?? 1);
            }}
          >
            <Back />
          </Button>
          <Button
            className="next-button"
            type={ButtonType.PRIMARY}
            disabled={meta?.nextPage === null}
            onClick={() => {
              handlePagination(meta?.nextPage ?? 1);
            }}
          >
            Next
            <Back className="invert--hr" />
          </Button>
        </div>
        <div className="page-counter type-text">
          <Formik
            enableReinitialize
            initialValues={{ page: { value: meta?.page ?? 0 } }}
            onSubmit={({ page }) => {
              handlePagination(page.value);
            }}
          >
            {({ submitForm }) => (
              <Form>
                <InputNumber.Formik
                  name="page"
                  onPressEnter={(e) => {
                    submitForm;
                  }}
                  addonAfter={<>of {meta?.totalPages}</>}
                  addonBefore={<>Page</>}
                  keyboard
                  max={meta?.totalPages}
                  min={1}
                ></InputNumber.Formik>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FilteredProducts;
