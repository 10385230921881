import * as Yup from "yup";
import { getLocationValidation } from "models/Location/location.validation";
import { measurementValidationSchema } from "models/Measurement/measurement.validation";
import { sustainabilityValidationSchema } from "models/Sustainability/sustainability.validation";
import { variantValidation } from "models/Variant/variant.validation";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";

export default Yup.object().shape({
  code: Yup.string().required("SKU is required").min(3).max(16).label("SKU"),
  attachments: Yup.array().of(getObjectIdSchema(false)),
  description: Yup.string().notRequired(),
  measurement: measurementValidationSchema.notRequired(),
  name: Yup.string().required("Name is required"),
  productionLocation: getLocationValidation(false).required(
    "Location is required"
  ),
  sustainability: sustainabilityValidationSchema,
  category: getObjectIdSchema().required("Category is required"),
  subCategory: getObjectIdSchema().required("Sub-category is required"),
  type: getObjectIdSchema().required("Type is required"),
  variants: Yup.array().of(variantValidation),
  vendor: Yup.object().shape({
    name: Yup.string().required("Vendor is required"),
  }),
  manufacturer: Yup.string().notRequired(),
  model: Yup.string().notRequired(),
});
