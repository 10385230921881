import React, { useEffect } from "react";
import "./spaceTypeLayerVariantView.scss";
import { useParams } from "react-router-dom";
import { DefaultOptionType } from "antd/es/select";
import { Space, Spin } from "antd";
import Label from "shared/components/Label";
import ProductDescription from "views/ViewProduct/ViewProductContent/ProductDescription";
import Divider from "shared/components/Divider";
import DimensionAndCost from "views/ViewProduct/ViewProductContent/VariantDetails/DimensionAndCost";
import InventoryDetails from "views/ViewProduct/ViewProductContent/VariantDetails/InventoryDetails";
import Sustainability from "views/ViewProduct/ViewProductContent/Sustainability";
import Attachments from "shared/components/Attachments";
import { CloseOutlined } from "@ant-design/icons";
import { Replace, Remove } from "shared/icons";
import { Popover } from "antd";
import { useSpaceTypePinMarkers } from "context/SpaceTypePinMarkersContext";
import useRedirect from "shared/hooks/useRedirect";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import { useSpaceTypeSelectedLayer } from "context/SpaceTypeSelectedLayerContext";
import { useSelectedSpaceTypeProduct } from "context/SelectedSpaceTypeProductContext";
import { useSpaceTypeLayerProduct } from "shared/hooks/useSpaceTypeLayerProduct";
import { useSpaceTypeLayerDetails } from "shared/hooks/useSpaceTypeLayer";

const SpaceTypeLayerVariantView = () => {
  const { spaceTypeId } = useParams();
  const { selectedLayerId, layer, updateLayerDetails } =
    useSpaceTypeSelectedLayer();
  const {
    selectedProduct,
    isViewingProduct,
    handleSetSelectedProduct,
    handleSetIsViewingProduct,
  } = useSelectedSpaceTypeProduct();
  const { removePinMarker } = useSpaceTypePinMarkers();
  const { redirectToSpaceTypeProductListReplace } = useRedirect();
  const { removeProductFromSpaceTypeLayer } = useSpaceTypeLayerProduct();

  const { refetch: refetchLayerDetails } = useSpaceTypeLayerDetails(
    spaceTypeId,
    selectedLayerId
  );

  const cardDetails: DefaultOptionType[] = [
    {
      label: "Item Name",
      value: selectedProduct?.variant?.name,
      id: 1,
    },
    {
      label: "Color",
      value: selectedProduct?.variant?.color?.name,
      id: 2,
    },
    {
      label: "Material",
      value: selectedProduct?.variant?.material?.name,
      id: 3,
    },
    {
      label: "Finish",
      value: selectedProduct?.variant?.finish?.name,
      id: 4,
    },
  ]?.filter(({ value }) => Boolean(value));

  const handleRemove = async () => {
    if (spaceTypeId && selectedLayerId && selectedProduct?.id) {
      try {
        const deleted = await removeProductFromSpaceTypeLayer.mutateAsync({
          spaceTypeId,
          layerId: selectedLayerId,
          layerVariantId: selectedProduct.id,
        });

        if (layer?.products && deleted) {
          let idx = -1;
          updateLayerDetails?.({
            ...layer,
            products: layer?.products?.filter(({ id }) => {
              idx += 1;
              return id !== selectedProduct.id;
            }),
          });
          await refetchLayerDetails?.();
          removePinMarker?.(idx); // Remove the first pin marker
          handleSetSelectedProduct?.(undefined);
          handleSetIsViewingProduct?.(false);
        }
      } catch (error) {
        // Error handling is done in the mutation
        return;
      }
    }
  };

  return (
    <div className="layer-variant-details">
      {!selectedProduct ? (
        <div className="display-flex flex-justify-center width-100">
          <Spin />
        </div>
      ) : (
        <>
          <div className="layer-variant-details__actions">
            <Popover content="Replace mapped product">
              <div
                className="layer-variant-details__action"
                onClick={() => {
                  if (spaceTypeId && selectedLayerId && selectedProduct?.id) {
                    redirectToSpaceTypeProductListReplace(
                      spaceTypeId,
                      selectedLayerId,
                      selectedProduct.id
                    );
                  }
                }}
              >
                <Replace className="icon" />
              </div>
            </Popover>
            <Popover content="Remove mapped product">
              <div
                className="layer-variant-details__action"
                onClick={handleRemove}
              >
                <Remove className="icon" />
              </div>
            </Popover>
            <Popover content="Close">
              <div
                className="layer-variant-details__action"
                onClick={() => {
                  handleSetSelectedProduct?.(undefined);
                  handleSetIsViewingProduct?.(false);
                }}
              >
                <CloseOutlined className="icon" />
              </div>
            </Popover>
          </div>
          <div className="view-product-content__start">
            <LazyLoadedImage
              src={selectedProduct?.variant?.image?.url ?? ""}
              alt="Product Image"
              imageClassName="view-product-content__image"
              aspectRatio="square"
              active={!!selectedProduct?.variant?.image?.url}
            />
          </div>
          <div className="product-name">
            {selectedProduct?.variant?.product?.name}
          </div>
          <div className="variant-details">
            <div className="variant-details__card">
              {cardDetails?.map(({ label, value, id }) => (
                <Space key={id} direction="vertical">
                  <Label className="text-sm text-primary" label={label} />
                  <span>{value}</span>
                </Space>
              ))}
            </div>
            {selectedProduct?.variant?.product && (
              <>
                <ProductDescription
                  product={selectedProduct?.variant?.product}
                />
                <Divider />
                <DimensionAndCost
                  product={selectedProduct?.variant?.product}
                  variant={selectedProduct?.variant}
                />
                <Divider />
                <InventoryDetails
                  product={selectedProduct?.variant?.product}
                  variant={selectedProduct?.variant}
                />
              </>
            )}
            {selectedProduct?.variant?.product?.sustainability
              ?.isSustainable && (
              <>
                <Divider />
                <Sustainability
                  sustainability={
                    selectedProduct?.variant?.product?.sustainability
                  }
                />
              </>
            )}
            {(selectedProduct?.variant?.product?.attachments?.length ||
              selectedProduct?.variant?.product?.threeDimensionModel) && (
              <>
                <Divider />
                <Attachments
                  attachments={[
                    ...(selectedProduct?.variant?.product?.threeDimensionModel
                      ? [selectedProduct?.variant?.product?.threeDimensionModel]
                      : []),
                    ...(selectedProduct?.variant?.product?.attachments || []),
                  ]}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SpaceTypeLayerVariantView;
