import React from "react";
import { SpaceTypeGetData } from "types/spaceTypes";
import SpaceTypeExpandableCard from "../SpaceTypeExpandableCard";
import { Layer } from "models/Layer/layer.model";
import useRedirect from "shared/hooks/useRedirect";
import { useSpaceTypeSelectedLayer } from "context/SpaceTypeSelectedLayerContext";

interface DualViewLeftSideProps {
  loading: boolean;
  viewDetails: SpaceTypeGetData;
  layerList?: Layer[];
}

const DualViewLeftSide = ({
  loading,
  viewDetails,
  layerList,
}: DualViewLeftSideProps) => {
  const { redirectToSpaceTypeAddLayer } = useRedirect();
  const { selectedLayerId, updateSelectedLayerId, updateLayerDetails } =
    useSpaceTypeSelectedLayer();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dual-view__left-side tw-px-8">
      <h1 className="top-level-heading">{viewDetails.title}</h1>
      <div className="dual-view__left-side__content tw-w-[350px] tw-max-w-[350px]">
        {layerList?.map((layer) => (
          <SpaceTypeExpandableCard
            key={layer.id}
            subView={layer}
            onLayerSelect={(layerId) => {
              if (layerId) {
                updateSelectedLayerId?.(layerId);
                updateLayerDetails?.(layer);
              } else {
                updateSelectedLayerId?.(undefined);
                // updateLayerDetails?.(undefined);
              }
            }}
            selectedLayerId={selectedLayerId}
          />
        ))}
      </div>
      <button
        className="tw-bg-transparent tw-border-none tw-text-primary tw-cursor-pointer tw-tracking-wide"
        onClick={() => {
          redirectToSpaceTypeAddLayer(viewDetails.id);
        }}
      >
        &#43;&nbsp;Add Layer
      </button>
    </div>
  );
};

export default DualViewLeftSide;
