import React, { FC, useEffect, useState } from "react";
import "./categoryNavbar.scss";
import { useMeta } from "services/useMetaService/useMeta.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { Category } from "models/Category/category.model";
import ProductService from "services/ProductService/product.service";
import { Col, Row, Skeleton } from "antd";
import { ProductParams } from "models/Product/product.model";

interface CategoryNavbarType {
  setParams: React.Dispatch<React.SetStateAction<ProductParams>>;
}

const INITIAL_CATEGORY_COUNT = 10;

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const CategoryNavbar: FC<CategoryNavbarType> = ({ setParams }) => {
  const { fetchProductsMeta } = ProductService();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [showAllCategories, setShowAllCategories] = useState(false);

  const {
    fetchMeta: fetchCategories,
    meta: categories,
    loading: categoriesLoading,
  } = useMeta({
    key: "categories",
    url: ApiRoutes.CATEGORIES,
    serializingClass: Category,
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    fetchProductsMeta();
    fetchCategories();
  }, []);

  const displayedCategories = showAllCategories
    ? categories
    : categories?.slice(0, INITIAL_CATEGORY_COUNT);

  return (
    <div className="category-navbar">
      <div className="category-navbar__content">
        <Skeleton loading={categoriesLoading}>
          <Row>
            {displayedCategories?.map((category) => (
              <Col
                span={5}
                key={category?.id}
                className="gutter-row category-navbar__content__name"
              >
                <a
                  href={`#${category?.id}`}
                  className="tw-tracking-wide tw-text-3xl"
                >
                  {category?.name}
                </a>
              </Col>
            ))}
            {categories?.length > INITIAL_CATEGORY_COUNT && (
              <Col
                span={5}
                className="category-navbar__content__name text-color-primary cursor-pointer tw-text-2xl"
                onClick={() => setShowAllCategories(!showAllCategories)}
              >
                {showAllCategories ? "Show Less" : "View All"}
              </Col>
            )}
          </Row>
        </Skeleton>
      </div>
    </div>
  );
};

export default CategoryNavbar;
