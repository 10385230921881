import * as React from "react";
import "./space-type-image-marker.scss";
import { calculateMarkerPosition } from "../../../utils";
import { DBSCAN } from "density-clustering";
import { useTransformEffect } from "react-zoom-pan-pinch";
import GroupedPin from "../SpaceTypeGroupedPin";
import MarkerComp from "../SpaceTypeMarker";
import { useCanAddPin } from "context/CanAddPinContext";
import { SpaceTypePinMarkerType } from "context/SpaceTypePinMarkersContext";
import { useSelectedVariantId } from "context/SelectedVariantContext";
import LazyLoadedImage from "../LazyLoadedImage";
import useToggle from "shared/hooks/useToggle";

const DEFAULT_BUFFER = 0;

export type MarkerComponentProps = {
  top: number;
  left: number;
  itemNumber: number;
};

interface Props {
  src: string;
  markers: Array<SpaceTypePinMarkerType>;
  onAddMarker?: (marker: SpaceTypePinMarkerType) => void;
  markerComponent?: React.FC<MarkerComponentProps>;
  bufferLeft?: number;
  bufferTop?: number;
  alt?: string;
  extraClass?: string;
  isMarkerGroupDisabled?: boolean;
}

const SpaceTypeImageMarker: React.FC<Props> = ({
  src,
  markers,
  onAddMarker,
  markerComponent: MarkerComponent,
  bufferLeft = DEFAULT_BUFFER,
  bufferTop = DEFAULT_BUFFER,
  alt = "Markers",
  extraClass = "",
}) => {
  const [scale, setScale] = React.useState<number>();
  const { handleSetAddPin, canAddPin, detailsToBePinned } = useCanAddPin();
  const { isToggled, toggleOn } = useToggle(false);
  const { handlePinProductToLayer } = useSelectedVariantId();

  useTransformEffect((ref) => {
    setScale(ref.instance?.transformState?.scale);
  });

  const dbscan = new DBSCAN();

  const dataset = markers.map<number[]>(({ coordinate }) =>
    coordinate ? [+(coordinate?.x ?? ""), +(coordinate?.y ?? "")] : []
  );

  const neighborhoodRadius = (scale || 1) <= 1 ? 2 : -1;

  const clusters = dbscan.run(dataset, neighborhoodRadius, 2);
  const noise = dbscan.noise;
  const imageRef = React.useRef<HTMLImageElement>(null);

  const handleImageClick = (event: React.MouseEvent) => {
    if (!imageRef.current || !onAddMarker) {
      return;
    }
    if (!canAddPin) {
      return;
    }

    const imageDimensions = imageRef.current.getBoundingClientRect();

    const [top, left] = calculateMarkerPosition(
      event,
      imageDimensions,
      window.scrollY,
      bufferLeft,
      bufferTop
    );

    onAddMarker({
      coordinate: {
        x: left,
        y: top,
      },
      productId: detailsToBePinned?.id ?? "",
      productName: detailsToBePinned?.name ?? "",
    });

    handleSetAddPin?.(false);
    handlePinProductToLayer?.({ x: left, y: top });
  };

  const getProductIDsForCluster = (
    rest: number[],
    markers: SpaceTypePinMarkerType[],
    initialClusterIndex: number
  ) => {
    const productIDs = markers
      .filter((marker) => {
        return (
          rest.some(
            (otherIndex) => markers[otherIndex].coordinate === marker.coordinate
          ) || markers[initialClusterIndex].coordinate === marker.coordinate
        );
      })
      .map((marker) => marker.productId);

    return productIDs;
  };

  const getItemPosition = (marker: SpaceTypePinMarkerType) => {
    return {
      top: `${marker?.coordinate?.y}%`,
      left: `${marker?.coordinate?.x}%`,
    };
  };

  const [selectedMarker, setSelectedMarker] = React.useState<string>();

  return (
    <div className="space-type-image-marker">
      <LazyLoadedImage
        src={src}
        alt={alt}
        handleImageClick={handleImageClick}
        imageClassName={"space-type-image-marker__image " + extraClass}
        loaderClassName={`space-type-image-marker__loader`}
        imgRef={imageRef}
        aspectRatio="auto"
        onLoad={toggleOn}
      />
      {isToggled &&
        clusters.map(([initialClusterIndex, ...rest], index) => {
          const clusterIDs = getProductIDsForCluster(
            rest,
            markers,
            initialClusterIndex
          );
          return (
            <div
              className={`space-type-image-marker__marker ${
                !MarkerComponent && "space-type-image-marker__marker--default"
              }`}
              style={getItemPosition({
                coordinate: {
                  x: dataset[initialClusterIndex][0],
                  y: dataset[initialClusterIndex][1],
                },
                productId: "",
                productName: "",
              })}
              key={index}
              data-testid="marker"
            >
              <div>
                {clusterIDs.map((e) => (
                  <div key={e} id={e}></div>
                ))}
                {MarkerComponent ? (
                  <MarkerComponent
                    {...{
                      left: dataset[initialClusterIndex][0],
                      top: dataset[initialClusterIndex][1],
                    }}
                    itemNumber={index}
                  />
                ) : (
                  <GroupedPin
                    clusterIndex={initialClusterIndex}
                    clusterLength={rest?.length + 1}
                    dataset={dataset}
                  />
                )}
              </div>
            </div>
          );
        })}
      {isToggled &&
        noise.map((noiseIndex, index) => (
          <div
            className={`space-type-image-marker__marker ${
              !MarkerComponent && "space-type-image-marker__marker--default"
            }`}
            style={getItemPosition({
              coordinate: {
                x: dataset[noiseIndex][0],
                y: dataset[noiseIndex][1],
              },
              productId: "",
              productName: "",
            })}
            key={`1 ${index}`}
            data-testid="marker-1"
          >
            {MarkerComponent ? (
              <MarkerComponent
                {...{
                  left: dataset[noiseIndex][0],
                  top: dataset[noiseIndex][1],
                }}
                itemNumber={index}
              />
            ) : (
              <MarkerComp
                dataset={dataset}
                noiseIndex={noiseIndex}
                selectedMarker={selectedMarker}
                setSelectedMarker={setSelectedMarker}
                space={
                  markers && markers.length > noiseIndex
                    ? {
                        id: markers[noiseIndex]?.productId,
                        title: markers[noiseIndex]?.productName,
                      }
                    : undefined
                }
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default SpaceTypeImageMarker;
