import React, { FC, useEffect, useState } from "react";
import { Layer, LayerVariant } from "models/Layer/layer.model";
import { AccordionOpen, Edit, Eye, Pin, RemovePin } from "shared/icons";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import useRedirect from "shared/hooks/useRedirect";
import useToggle from "shared/hooks/useToggle";
import { useParams } from "react-router-dom";
import ToggleDescriptionIcon from "shared/components/ToggleDescriptionIcon";
import DescriptionModal from "shared/components/DescriptionModal";
import AddItemIcon from "shared/components/AddItemIcon";
import { useSpaceTypeLayerDetails } from "shared/hooks/useSpaceTypeLayer";
import { Tooltip } from "antd";
import "./spaceTypeExpandableCard.scss";
import { useSelectedSpaceTypeProduct } from "context/SelectedSpaceTypeProductContext";
import { useCanAddPin } from "context/CanAddPinContext";
import classNames from "classnames";

interface SpaceTypeExpandableCardProps {
  subView: Layer;
  viewId?: string;
  onLayerSelect?: (layerId: string) => void;
  selectedLayerId?: string;
}

const SpaceTypeExpandableCard: FC<SpaceTypeExpandableCardProps> = ({
  subView,
  onLayerSelect,
  selectedLayerId,
}) => {
  const { spaceTypeId } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const { redirectToSpaceTypeEditLayer, redirectToSpaceTypeProductList } =
    useRedirect();
  const {
    isToggled: descModalToggled,
    toggleOn: descModalToggleOn,
    toggleOff: descModalToggleOff,
  } = useToggle();

  const {
    selectedProduct,
    handleSetSelectedProduct,
    handleSetIsViewingProduct,
  } = useSelectedSpaceTypeProduct();

  const { handleSetAddPin, handleSetAddPinningDetails } = useCanAddPin();

  // Fetch layer details when expanded
  const { data: layerDetails } = useSpaceTypeLayerDetails(
    spaceTypeId,
    isExpanded ? subView?.id : undefined
  );

  useEffect(() => {
    setIsExpanded(selectedLayerId === subView?.id);
  }, [selectedLayerId, subView?.id]);

  const handleExpand = () => {
    const newLayerId = selectedLayerId === subView?.id ? "" : subView?.id ?? "";
    onLayerSelect?.(newLayerId);
    setIsExpanded(!isExpanded);

    // Clear selected layer when closing
    if (selectedLayerId === subView?.id) {
      handleSetSelectedProduct?.(undefined);
      handleSetIsViewingProduct?.(false);
    }
  };

  const handleEditLayer = () => {
    if (spaceTypeId && subView?.id) {
      redirectToSpaceTypeEditLayer(spaceTypeId, subView.id);
    }
  };

  const handleAddProduct = () => {
    if (spaceTypeId && layerDetails?.id) {
      redirectToSpaceTypeProductList(spaceTypeId, layerDetails.id);
    }
  };

  const handleViewProduct = (productId: string) => {
    const product = displayProducts.find((p) => p.id === productId);
    if (product) {
      handleSetSelectedProduct?.(product);
      handleSetIsViewingProduct?.(true);
    }
  };

  const handlePinProduct = (productId: string, productTitle: string) => {
    handleSetAddPin?.(true);
    handleSetAddPinningDetails?.(productId, productTitle);
  };

  const handleUnpinProduct = (productId: string) => {
    // Implement unpin product functionality
    console.log("Unpin product:", productId);
  };

  const handleProductClick = (product: LayerVariant) => {
    handleSetSelectedProduct?.(product);
  };

  const isProductPinned = (product: LayerVariant) => {
    return product.isPinned || !!product.coordinate;
  };

  // Use layerDetails if available, otherwise fall back to subView
  const displayLayer = layerDetails || subView;
  const displayProducts = layerDetails?.products || [];

  return (
    <div className="card-container">
      <div
        className={`plan-or-layer-card ${isExpanded ? "expanded" : ""}`}
        onClick={handleExpand}
      >
        <div className="plan-or-layer-card__details">
          <Tooltip title={displayLayer?.title}>
            <div
              className={classNames(
                "plan-or-layer-card__title tw-text-2xl tw-max-w-80 tw-truncate",
                {
                  "tw-max-w-52": isExpanded,
                }
              )}
            >
              {displayLayer?.title}
            </div>
          </Tooltip>
          {isExpanded && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                descModalToggleOn();
              }}
              className="tw-ml-2"
            >
              <ToggleDescriptionIcon active={descModalToggled} />
            </div>
          )}
        </div>
        <div className="plan-or-layer-card__actions">
          {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
            <div
              className={`dual-view-icons__icon-gp__icon ${
                isExpanded ? "" : "icon-hidden"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleEditLayer();
              }}
            >
              <Edit className="icon icon--edit tw-w-8 tw-h-auto" />
            </div>
          )}
          <div className={`dual-view-icons__icon-gp__icon tw-m-0`}>
            {isExpanded ? (
              <AccordionOpen
                className="icon icon--accordion 
              tw-m-0 icon--accordion--closed tw-w-8 tw-h-auto"
              />
            ) : (
              <AccordionOpen
                className="icon icon--accordion tw-w-8
              tw-m-0 tw-h-auto"
              />
            )}
          </div>
        </div>
      </div>
      {isExpanded && layerDetails && (
        <>
          <DescriptionModal
            title={displayLayer?.title ?? ""}
            type="LAYER"
            desc={displayLayer?.description ?? ""}
            isOpen={descModalToggled}
            onClose={descModalToggleOff}
          />
          {userHasAccess(Module.SPACE_TYPES, Method.EDIT) && (
            <div className="space-card tw-pb-0">
              <div className="">
                <span className="disp tw-mb-0">
                  <span className="space-heading">PRODUCT</span>
                  <AddItemIcon
                    onAdd={handleAddProduct}
                    tooltipOptions={{
                      title: "Add Product",
                    }}
                  />
                </span>
              </div>
            </div>
          )}
          {displayProducts.length > 0 && (
            <div className="space-card">
              {displayProducts.map((product) => (
                <div
                  key={product.id}
                  className={`space-list ${
                    selectedProduct?.id === product.id ? "selected" : ""
                  }`}
                  onClick={() => handleProductClick(product)}
                >
                  <div className="display-flex space-between">
                    <span
                      className="tw-text-xl tw-max-w-[150px] tw-truncate tw-overflow-hidden"
                      title={product.title}
                    >
                      {product.title}
                    </span>
                    <div
                      className={`tw-flex tw-items-center tw-gap-2 ${
                        selectedProduct?.id === product.id ? "" : "d-none"
                      }`}
                    >
                      {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                        <Tooltip
                          title={`${
                            isProductPinned(product)
                              ? "Remove Pin"
                              : "Pin Product"
                          }`}
                        >
                          {isProductPinned(product) ? (
                            <RemovePin
                              className="icon-pin"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUnpinProduct(product.id as string);
                              }}
                            />
                          ) : (
                            <Pin
                              className="icon-pin"
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePinProduct(
                                  product.id as string,
                                  product.title
                                );
                              }}
                            />
                          )}
                        </Tooltip>
                      )}
                      <Tooltip title="View Product">
                        <Eye
                          className="icon-pin"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewProduct(product.id as string);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SpaceTypeExpandableCard;
