import React, { FC } from "react";
import { Variant } from "models/Variant/variant.model";
import { DefaultOptionType } from "antd/es/select";
import { Product } from "models/Product/product.model";
import Collapse from "shared/components/Collapse";
import { Inventory } from "shared/icons";

import "./inventoryDetails.scss";
import { priceUnitTypesMap } from "shared/constants/products";

interface InventoryDetailsProps {
  product: Product;
  variant: Variant;
}

const InventoryDetails: FC<InventoryDetailsProps> = ({ product, variant }) => {
  const priceTypeString = variant?.cost?.type
    ? ` / ${priceUnitTypesMap[variant?.cost?.type]}`
    : "";

  const details: DefaultOptionType[] = [
    {
      label: "Vendor",
      value: product?.vendor?.name,
    },
    {
      label: "Available count",
      value: variant?.count && `${variant?.count} units`,
    },
    {
      label: "Max Lead Time (weeks)",
      value: variant?.leadTime && `${variant?.leadTime} weeks`,
    },
    {
      label: "Production country",
      value: product?.productionLocation?.country?.name || "N/A",
    },
    {
      label: "Production province/state",
      value: product?.productionLocation?.province?.name || "N/A",
    },
    {
      label: "Stored country",
      value: variant?.location?.country?.name,
    },
    {
      label: "Stored province/state",
      value: variant?.location?.province?.name,
    },
    {
      label: "Contract Sell Price",
      value: `$${variant?.cost?.value?.toFixed(2)}${priceTypeString}`,
    },
    // {
    //   label: "Manufacturer",
    //   value: product?.manufacturer,
    // },
    // {
    //   label: "Model",
    //   value: product?.model,
    // },
  ]?.filter(({ value }) => Boolean(value));

  const items = [
    {
      id: 1,
      details,
      getHeader: () => (
        <div className="collapse-icon-header">
          <Inventory className="icon-grey-3 icon--inventory" />
          <span>Inventory details</span>
        </div>
      ),
    },
  ];

  return (
    <div className="inventory-details">
      <Collapse defaultActiveKey={items?.[0]?.id} list={items}>
        {({ details }) => (
          <>
            {details?.map(({ label, value }) => (
              <>
                <span className="text-grey-2">{label}</span>
                <span className="inventory-details__value">{value}</span>
              </>
            ))}
          </>
        )}
      </Collapse>
    </div>
  );
};

export default InventoryDetails;
