import { Coordinate } from "models/Coordinate/coordinate.model";
import { Zone } from "models/Zone/zone.model";
import React, { FC, useEffect, useState } from "react";
import useRedirect from "shared/hooks/useRedirect";
import { useSelectedZone } from "context/SelectedZoneContext";
import { useSelectedPlan } from "context/SelectedPlanContext";
import { useSelectedSpace } from "context/SelectedSpaceContext";
import { usePinMarkers } from "context/PinMarkersContext";
import { useParams } from "react-router-dom";
import useToggle from "shared/hooks/useToggle";
import { Menu, MenuProps, Tooltip } from "antd";
import { DiagramView } from "enums/diagramView";
import ToggleDescriptionIcon from "shared/components/ToggleDescriptionIcon";
import DescriptionModal from "shared/components/DescriptionModal";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { EditGrey, Eye, Pin, RemovePin } from "shared/icons";
import AddItemIcon from "shared/components/AddItemIcon";
import { getSubviewTitle } from "shared/utils/parser";
import { useCanAddPin } from "context/CanAddPinContext";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import AddSpaceModal from "shared/components/AddSpaceModal";

export default function ZoneAccordion(props: {
  subViewMappingList: Zone[];
  handleSelectSuperSubViewActive: (
    superSubViewId: string,
    superSubViewCoordinate?: Coordinate
  ) => void;
  isActiveSuperSubView: (superSubViewId?: string) => string;
  isSuperSubViewSelected: (superSubViewId: string) => boolean;
  setOpenedLayerVariantId?: (id: string) => void;
  subViewType: DiagramView;
  viewId?: string;
  subViewId?: string;
  categories?: SpaceTypeCategory[];
}) {
  const {
    subViewMappingList,
    handleSelectSuperSubViewActive,
    isActiveSuperSubView,
    isSuperSubViewSelected,
    subViewType,
  } = props;
  const {
    redirectToSpaceView,
    redirectToEditZoneForm,
    redirectToAddSpaceForm,
  } = useRedirect();
  const { handleSetSelectedZoneId, selectedZoneId, handleRemoveZonePin } =
    useSelectedZone();
  const { selectedPlanId } = useSelectedPlan();
  const { removePin, updatingPin } = useSelectedSpace();
  const [subMenuOpenKeys, setSubMenuOpenKeys] = React.useState<string[]>([]);
  const { removePinMarkerForSpaceId } = usePinMarkers();

  const { prototypeId } = useParams();
  const { isToggled, toggleOn, toggleOff } = useToggle(false);

  const { handleSetAddPin, handleSetAddPinningDetails, canAddPin } =
    useCanAddPin();

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>();

  const [isAddSpaceModalOpen, setIsAddSpaceModalOpen] = useState(false);
  const [selectedZone, setSelectedZone] = useState<Zone>();

  const [selectedSpaceType, setSelectedSpaceType] = useState<string>();

  const handleSelect: MenuProps["onClick"] = (e) => {
    const superSubView = subViewMappingList.find((zone) => {
      const space = zone.spaces?.find((space) => space.id === e.key);
      return space;
    });

    if (superSubView) {
      const space = superSubView.spaces?.find((space) => space.id === e.key);
      if (space) {
        handleSelectSuperSubViewActive(space.id as string, space.coordinate);
      }
    }
  };

  const onSubmenuClick = (e: any) => {
    if (e.key && e.key.length > 0 && handleSetSelectedZoneId) {
      if (selectedZoneId === e.key) {
        handleSetSelectedZoneId(undefined);
      }

      if (subMenuOpenKeys.includes(e.key)) {
        setSubMenuOpenKeys([]);
        return;
      }

      handleSetSelectedZoneId(e.key);
      setSubMenuOpenKeys([e.key]);
    }
  };

  useEffect(() => {
    if (selectedZoneId) {
      setSubMenuOpenKeys([selectedZoneId]);
    } else {
      setSubMenuOpenKeys([]);
    }
  }, [selectedZoneId]);

  return (
    <>
      <Menu
        onClick={handleSelect}
        mode="inline"
        className=" tw-border-r-0"
        openKeys={subMenuOpenKeys}
      >
        {subViewMappingList.map((zone) => {
          return (
            <Menu.SubMenu
              key={zone.id}
              icon={null}
              title={
                <div className="tw-flex tw-items-center tw-justify-between">
                  <div className=" tw-flex tw-items-center tw-gap-4">
                    {zone.image?.url && (
                      <img
                        src={zone.image?.url}
                        alt=""
                        className=" tw-w-12 tw-h-12"
                      ></img>
                    )}

                    <p
                      className="tw-text-2xl tw-max-w-[150px] tw-truncate tw-overflow-hidden"
                      title={zone.title}
                    >
                      {zone.title}
                    </p>
                    {selectedZoneId === zone.id && (
                      <>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleOn();
                          }}
                        >
                          <ToggleDescriptionIcon
                            active={isToggled}
                          ></ToggleDescriptionIcon>
                        </div>
                        <DescriptionModal
                          title={zone.title ?? ""}
                          type={subViewType}
                          desc={zone.description ?? ""}
                          isOpen={isToggled}
                          onClose={() => {
                            toggleOff();
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div className=" tw-flex tw-items-center tw-justify-center">
                    {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                      <Tooltip
                        title={`${zone?.isPinned ? "Remove Pin" : "Pin Zone"}`}
                        className="tw-w-8 tw-h-8 tw-mr-2"
                      >
                        {zone?.isPinned ? (
                          <RemovePin
                            className="icon-pin"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!updatingPin && zone?.id) {
                                //   removePin?.(space.id);
                                //   removePinMarkerForSpaceId?.(space.id);

                                handleRemoveZonePin?.(zone.id);
                              }
                            }}
                          />
                        ) : (
                          //ToDo: check if on disabling can add pin and still sending the setAddPinDetails has any buggy behaviour
                          <Pin
                            className="icon-pin"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSetSelectedZoneId?.(undefined);
                              handleSetAddPin?.(!canAddPin);

                              if (zone.id && zone.title) {
                                handleSetAddPinningDetails?.(
                                  zone.id,
                                  zone.title
                                );
                              }
                              // space?.id &&
                              //   space?.title &&
                              //   handleSetAddPinningDetails?.(
                              //     space?.id,
                              //     space?.title
                              //   );
                            }}
                          />
                        )}
                      </Tooltip>
                    )}

                    {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          redirectToEditZoneForm(
                            prototypeId || "",
                            selectedPlanId || "",
                            zone.id || ""
                          );
                        }}
                        className="tw-border-none tw-bg-transparent hover:tw-cursor-pointer"
                      >
                        <EditGrey className="tw-w-8" />
                      </button>
                    )}
                  </div>
                </div>
              }
              onTitleClick={(e) => onSubmenuClick(e)}
              className="expandable-card__zone-submenu"
            >
              {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                <Menu.Item key={`${zone.id as string}-add-space`}>
                  <div className="tw-mt-8">
                    <span className="disp tw-flex tw-items-center tw-gap-4">
                      <span className="space-heading">SPACE</span>
                      <AddItemIcon
                        onAdd={() => {
                          setSelectedZone(zone);
                          setIsAddSpaceModalOpen(true);
                        }}
                        tooltipOptions={{
                          title: "Add Space",
                        }}
                      />
                    </span>
                  </div>
                </Menu.Item>
              )}
              {zone.spaces?.map((space) => {
                return (
                  <Menu.Item key={space.id}>
                    {/* <span className="navbar__menu__item tw-flex tw-items-center tw-tracking-wide">
                        {space.title}
                      </span> */}
                    <div
                      className={`space-list ${isActiveSuperSubView(
                        space?.id
                      )}`}
                      key={space?.id}
                      onClick={() =>
                        space?.id &&
                        handleSelectSuperSubViewActive(
                          space?.id,
                          space?.coordinate
                        )
                      }
                    >
                      <div className="display-flex space-between">
                        <span
                          className="tw-text-xl tw-max-w-[150px] tw-truncate tw-overflow-hidden"
                          title={space?.title}
                        >
                          {space?.title}
                        </span>
                        <div
                          className={`${
                            space?.id && isSuperSubViewSelected(space?.id)
                              ? "tw-flex tw-items-center tw-gap-2"
                              : "d-none"
                          }`}
                        >
                          {userHasAccess(Module.PROTOTYPE, Method.EDIT) && (
                            <Tooltip
                              title={`${
                                space?.isPinned ? "Remove Pin" : "Pin Space"
                              }`}
                            >
                              {space?.isPinned ? (
                                <RemovePin
                                  className="icon-pin"
                                  onClick={() => {
                                    if (!updatingPin && space?.id) {
                                      removePin?.(space.id);
                                      removePinMarkerForSpaceId?.(space.id);
                                    }
                                  }}
                                />
                              ) : (
                                //ToDo: check if on disabling can add pin and still sending the setAddPinDetails has any buggy behaviour
                                <Pin
                                  className="icon-pin"
                                  onClick={() => {
                                    handleSetAddPin?.(!canAddPin);
                                    space?.id &&
                                      space?.title &&
                                      handleSetAddPinningDetails?.(
                                        space?.id,
                                        space?.title
                                      );
                                  }}
                                />
                              )}
                            </Tooltip>
                          )}
                          <Tooltip title={getSubviewTitle(subViewType)}>
                            <Eye
                              className="icon-pin"
                              onClick={() => {
                                // subViewType === DiagramView.PLAN
                                //   ? redirectToZoneView(
                                //       viewId ?? "",
                                //       subView?.id ?? "",
                                //       superSubView?.id ?? ""
                                //     )
                                //   : setOpenedLayerVariantId?.(superSubView?.id ?? "");
                                redirectToSpaceView(
                                  props.viewId ?? "",
                                  props.subViewId ?? "",
                                  selectedZoneId ?? "",
                                  space?.id ?? ""
                                );
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Menu.Item>
                );
              })}
              {/* </div> */}
            </Menu.SubMenu>
          );
        })}
      </Menu>
      <AddSpaceModal
        open={isAddSpaceModalOpen}
        onCancel={() => {
          setIsAddSpaceModalOpen(false);
          setSelectedCategory(undefined);
          setSelectedSubCategory(undefined);
          setSelectedSpaceType(undefined);
        }}
        onCreateNew={() => {
          redirectToAddSpaceForm(
            prototypeId || "",
            selectedPlanId || "",
            selectedZone?.id || ""
          );
          setIsAddSpaceModalOpen(false);
        }}
        categories={props.categories}
        selectedCategory={selectedCategory}
        selectedSubCategory={selectedSubCategory}
        selectedSpaceType={selectedSpaceType}
        onCategoryChange={(value: string) => {
          setSelectedCategory(value);
          setSelectedSubCategory(undefined);
          setSelectedSpaceType(undefined);
        }}
        onSubCategoryChange={(value: string) => {
          setSelectedSubCategory(value);
          setSelectedSpaceType(undefined);
        }}
        onSpaceTypeChange={(value: string) => setSelectedSpaceType(value)}
      />
    </>
  );
}
