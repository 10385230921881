import React, { useEffect, useState } from "react";
import { useMeta } from "services/useMetaService/useMeta.service";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import FolderView from "shared/components/FolderView";
import FolderHeader from "shared/components/FolderHeader";
import EmptyPlaceholderSelect from "shared/components/EmptyPlaceholderSelect";
import SpaceTypeService from "services/SpaceTypeService/spaceType.service";
import ConfigItemForm from "../CategoryConfiguration/ConfigItemForm";
import FolderItemExtra from "../CategoryConfiguration/FolderItemExtra";
import DeleteConfigModal from "../SpaceTypeConfiguration/DeleteConfig/DeleteConfigModal";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { SpaceTypeSubCategory } from "models/SpaceType/spaceTypeSubCategory.model";
import { SpaceTypeConfiguration } from "models/SpaceType/spaceTypeConfiguration.model";
import { NotificationTypes } from "enums/notificationTypes";
import Notification from "shared/components/Notification";

import "./spaceConfiguration.scss";

const SpaceConfiguration = () => {
  const {
    addSpaceTypeCategory,
    addSpaceTypeSubCategory,
    updateSpaceTypeCategory,
    updateSpaceTypeSubCategory,
    deleteSpaceTypeCategory,
    deleteSpaceTypeSubCategory,
  } = SpaceTypeService();

  const {
    fetchMeta: fetchCategories,
    meta: categories,
    loading: categoriesLoading,
    setMeta: setCategories,
  } = useMeta({
    key: "space_type_categories",
    url: ApiRoutes.SPACE_TYPE_CATEGORIES,
    serializingClass: SpaceTypeCategory,
  });

  const [chosenCategory, setChosenCategory] = useState<SpaceTypeCategory>();
  const [chosenSubCategory, setChosenSubCategory] =
    useState<SpaceTypeSubCategory>();
  const [deleteConfig, setDeleteConfig] = useState<SpaceTypeConfiguration>();

  const handleCategoryClick = (category: SpaceTypeCategory) => {
    if (chosenCategory?.id === category?.id) return;
    setChosenCategory(category);
    setChosenSubCategory(undefined);
  };

  const handleSubCategoryClick = (subCategory: SpaceTypeSubCategory) => {
    if (chosenSubCategory?.id === subCategory?.id) return;
    setChosenSubCategory(subCategory);
  };

  const handleDeleteClick = () => {
    const config = new SpaceTypeConfiguration();
    if (chosenSubCategory?.id) {
      config.spaceSubType = chosenSubCategory;
    } else if (chosenCategory?.id) {
      config.spaceTypeCategory = chosenCategory;
    }
    setDeleteConfig(config);
  };

  const handleDeleteSubmit = async () => {
    if (!deleteConfig) return;

    let isDeleted = false;

    if (chosenSubCategory?.id && chosenCategory?.id) {
      isDeleted = await deleteSpaceTypeSubCategory(
        chosenSubCategory.id,
        chosenCategory.id
      );

      if (isDeleted) {
        // Update both categories and chosenCategory states
        setCategories((prevCategories) => {
          const updatedCategories = prevCategories.map((category) => {
            if (category.id === chosenCategory.id) {
              const updatedCategory = {
                ...category,
                subCategories: category.subCategories?.filter(
                  (sub) => sub.id !== chosenSubCategory.id
                ),
              };
              // Update chosenCategory with the new state
              setChosenCategory(updatedCategory);
              return updatedCategory;
            }
            return category;
          });
          return updatedCategories;
        });
        setChosenSubCategory(undefined);
      }
    } else if (chosenCategory?.id) {
      isDeleted = await deleteSpaceTypeCategory(chosenCategory.id);
      if (isDeleted) {
        await fetchCategories();
        setChosenCategory(undefined);
        setChosenSubCategory(undefined);
      }
    }

    setDeleteConfig(undefined);
  };

  const handleCategorySubmit = async (category: SpaceTypeCategory) => {
    const isUpdate = !!category?.id;
    const trimmedCategory = {
      ...category,
      name: category.name?.trim(),
    };

    const updatedCategory = isUpdate
      ? await updateSpaceTypeCategory(trimmedCategory)
      : await addSpaceTypeCategory(trimmedCategory);

    if (updatedCategory) {
      await fetchCategories();
    }
  };

  const handleSubCategorySubmit = async (subCategory: SpaceTypeSubCategory) => {
    try {
      const isUpdate = !!subCategory?.id;
      const trimmedSubCategory = {
        ...subCategory,
        name: subCategory.name?.trim(),
      };

      if (!chosenCategory?.id) {
        Notification({
          message: "Please select a category first",
          type: NotificationTypes.ERROR,
        });
        return;
      }

      const updatedSubCategory = isUpdate
        ? await updateSpaceTypeSubCategory(
            trimmedSubCategory,
            chosenCategory.id
          )
        : await addSpaceTypeSubCategory(trimmedSubCategory, chosenCategory.id);

      if (updatedSubCategory) {
        const updatedCategories = await fetchCategories();
        const updatedChosenCategory = updatedCategories.find(
          (cat: any) => cat.id === chosenCategory.id
        );
        setChosenCategory(updatedChosenCategory);
      }
    } catch (error) {
      console.error("Failed to submit sub category:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <div className="space-configuration">
        <div className="space-configuration__item">
          <FolderHeader
            title="Category"
            extraContent={
              <ConfigItemForm
                item={new SpaceTypeCategory()}
                onSubmit={handleCategorySubmit}
                title="Category"
              />
            }
          />
          <FolderView
            loading={categoriesLoading}
            items={categories?.map((value) => ({
              label:
                !chosenSubCategory && chosenCategory?.id === value?.id ? (
                  <FolderItemExtra
                    item={value}
                    onDelete={handleDeleteClick}
                    onUpdate={handleCategorySubmit}
                    title={value?.name}
                  />
                ) : (
                  value?.name
                ),
              value,
            }))}
            onClick={handleCategoryClick}
            activeItem={chosenCategory}
            isActiveSecondary={!!chosenSubCategory?.id}
          />
        </div>
        <div className="space-configuration__item">
          <FolderHeader
            title="Sub Category"
            extraContent={
              chosenCategory?.id && (
                <ConfigItemForm
                  item={new SpaceTypeSubCategory()}
                  onSubmit={handleSubCategorySubmit}
                  title="Sub Category"
                />
              )
            }
          />
          <FolderView
            loading={categoriesLoading}
            items={
              chosenCategory?.subCategories?.map((value) => ({
                label:
                  chosenSubCategory?.id === value?.id ? (
                    <FolderItemExtra
                      item={value}
                      onDelete={handleDeleteClick}
                      onUpdate={handleSubCategorySubmit}
                      title={value?.name}
                    />
                  ) : (
                    value?.name
                  ),
                value,
              })) || []
            }
            onClick={handleSubCategoryClick}
            activeItem={chosenSubCategory}
            hideNext
            emptyPlaceHolder={
              <EmptyPlaceholderSelect title="Select a category to view" />
            }
          />
        </div>
      </div>
      <DeleteConfigModal
        config={deleteConfig as SpaceTypeConfiguration}
        open={!!deleteConfig}
        onDelete={handleDeleteSubmit}
        onCancel={() => setDeleteConfig(undefined)}
      />
    </>
  );
};

export default SpaceConfiguration;
