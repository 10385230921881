import { ColumnType } from "antd/es/table";
import { Variant } from "models/Variant/variant.model";
import React, { FC } from "react";
import Table from "shared/components/Table";
import "./expandedRow.scss";
import { priceUnitTypesMap } from "shared/constants/products";

interface ExpandedRowType {
  variants: Variant[];
  selectedVariant?: string;
  setSelectedVariant: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ExpandedRow: FC<ExpandedRowType> = ({
  variants,
  setSelectedVariant,
  selectedVariant,
}) => {
  const variantColumn: ColumnType<Variant>[] = [
    {
      key: "name",
      title: "ITEM NAME",
      render(_: string, record: Variant) {
        return (
          <div className="display-flex justify-center">
            <img
              src={record?.image?.thumbnailUrl || record?.image?.url}
              height={28}
              width={28}
              style={{ marginRight: "1rem" }}
            />
            <div>{record?.name}</div>
          </div>
        );
      },
    },
    {
      key: "cost",
      title: "COST/ITEM",
      render(_: string, record: Variant) {
        const priceTypeString = record?.cost?.type
          ? ` / ${priceUnitTypesMap[record.cost.type]}`
          : "";
        return (
          <>
            ${record?.cost?.value}
            {priceTypeString}
          </>
        );
      },
    },
    {
      key: "count",
      title: "AVAILABLE COUNT",
      dataIndex: "count",
    },
    {
      key: "leadTime",
      title: "LEAD TIME",
      dataIndex: "leadTime",
    },
    {
      key: "country",
      title: "STORED COUNTRY",
      render(_: string, record: Variant) {
        return <>{record?.location?.country?.name}</>;
      },
    },
    {
      key: "state",
      title: "STORED PROVINCE/STATE",
      render(_: string, record: Variant) {
        return <>{record?.location?.province?.name}</>;
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedVariant(selectedRowKeys?.[0].toString());
    },
  };
  return (
    <div className="expanded-row">
      <Table
        columns={variantColumn}
        dataSource={variants?.map((variant) => ({
          ...variant,
          key: variant?.id,
        }))}
        pagination={false}
        rowSelection={{
          type: "radio",
          ...rowSelection,
          selectedRowKeys: selectedVariant ? [selectedVariant] : [],
        }}
      />
    </div>
  );
};

export default ExpandedRow;
