import { useMutation } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { Layer } from "models/Layer/layer.model";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "shared/constants/queries";

export const useSpaceTypeLayer = (spaceTypeId: string | undefined) => {
  const createSpaceTypeLayer = useMutation({
    mutationFn: async (layer: Layer) => {
      try {
        const endpoint = generatePath(ApiRoutes.SPACE_TYPE_LAYER, {
          spaceTypeId,
        });
        const { data } = await axiosInstance.post(endpoint, {
          layer: layer,
          space_type: {},
        });
        return data;
      } catch (err) {
        Notification({
          message: (err as Error)?.message || "Unable to create layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  const updateSpaceTypeLayer = useMutation({
    mutationFn: async (layer: Layer) => {
      try {
        const endpoint = generatePath(ApiRoutes.SPACE_TYPE_LAYER_ID, {
          spaceTypeId,
          layerId: layer.id,
        });
        const { data } = await axiosInstance.put(endpoint, layer);
        return data;
      } catch (err) {
        Notification({
          message: (err as Error)?.message || "Unable to update layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  const deleteSpaceTypeLayer = useMutation({
    mutationFn: async (layerId: string) => {
      try {
        const endpoint = generatePath(ApiRoutes.SPACE_TYPE_LAYER_ID, {
          spaceTypeId,
          layerId,
        });
        await axiosInstance.delete(endpoint);
      } catch (err) {
        Notification({
          message: (err as Error)?.message || "Unable to delete layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  return {
    createSpaceTypeLayer,
    updateSpaceTypeLayer,
    deleteSpaceTypeLayer,
  };
};

export const useSpaceTypeLayerDetails = (
  spaceTypeId?: string,
  layerId?: string
) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_SINGLE_SPACE_TYPE_LAYER,
      {
        spaceTypeId,
        layerId,
      },
    ],
    queryFn: async () => {
      if (!spaceTypeId || !layerId) {
        return null;
      }
      const path = generatePath(ApiRoutes.SPACE_TYPE_LAYER_ID, {
        spaceTypeId,
        layerId,
      });
      const { data } = await axiosInstance.get(path);
      return data.layer as Layer;
    },
    enabled: !!spaceTypeId && !!layerId,
    retry: false,
    refetchOnWindowFocus: false,
  });
};
