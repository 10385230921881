import React, { FC } from "react";
import { useEffect, useState } from "react";
import "./spaceTypeDiagramView.scss";
import SpaceTypeImageMarker from "../SpaceTypeImageMarker";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useCanAddPin } from "context/CanAddPinContext";
import { useSpaceTypePinMarkers } from "context/SpaceTypePinMarkersContext";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {
  addEventListenerEscCancelPinning,
  removeEventListenerEscCancelPinning,
} from "utils";

interface SpaceTypeDiagramViewProps {
  imgUrl: string;
  closeIcon?: React.ReactElement;
}

const SpaceTypeDiagramView: FC<SpaceTypeDiagramViewProps> = ({
  imgUrl,
  closeIcon,
}) => {
  const { canAddPin, handleSetAddPin, detailsToBePinned } = useCanAddPin();
  const { pinMarkers, addPinMarker } = useSpaceTypePinMarkers();

  const [className, setClassName] = useState("");

  const handleEsc = (evt: { key: string }) => {
    if (evt.key === "Escape") setClassName("");
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  useEffect(() => {
    addEventListenerEscCancelPinning(handleSetAddPin);
    return () => {
      removeEventListenerEscCancelPinning(handleSetAddPin);
    };
  }, []);

  return (
    <div className="diagram-view-outer cursor-space">
      <div
        className={`diagram-view ${className} ${canAddPin && "black-overlay"} ${
          canAddPin && "diagram-view-pin-cursor"
        }`}
      >
        <TransformWrapper
          disablePadding
          centerZoomedOut
          initialScale={1}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <React.Fragment>
              {closeIcon}
              <div className="dual-view-icons__icon-gp__zoom_icon">
                <ZoomInOutlined className="icon" onClick={() => zoomIn()} />
                <ZoomOutOutlined className="icon" onClick={() => zoomOut()} />
                <RedoOutlined
                  className="icon"
                  onClick={() => resetTransform()}
                />
              </div>
              <TransformComponent contentClass="pointer-all">
                <SpaceTypeImageMarker
                  markers={pinMarkers ?? []}
                  src={imgUrl}
                  onAddMarker={(marker) => {
                    if (canAddPin && addPinMarker) {
                      addPinMarker(marker);
                      if (handleSetAddPin) {
                        handleSetAddPin(false);
                      }
                    }
                  }}
                  extraClass={canAddPin ? "black-overlay-img" : ""}
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
      <div className={`${canAddPin ? "text-overlay" : "display-none"}`}>
        Place the pin here or press esc to cancel
      </div>
      <div className={`${canAddPin ? "background-overlay" : "display-none"}`} />
    </div>
  );
};

export default SpaceTypeDiagramView;
