export const CLOUD_PROVIDER = process.env.REACT_APP_CLOUD_PROVIDER || "aws";

export enum CONSTANTS {
  DEBOUNCE = 250,
  LARGE_SCREEN_WIDTH = 1440,
  MEDIUM_SCREEN_WIDTH = 1180,
  CENTI_TO_MILLI = 10,
  CENTI_TO_IN = 2.54,
  KG_TO_LBS = 2.204623,
  MAX_DESC_LENGTH_COLLAPSED = 280,
}
