import React from "react";
import { SpaceTypeGetData } from "types/spaceTypes";
import DualViewLeftSide from "./DualViewLeftSide";
import DualViewRightSide from "./DualViewRightSide";
import { DiagramView } from "enums/diagramView";
import { SelectedSpaceTypeProductState } from "context/SelectedSpaceTypeProductContext";
import CanAddPinState from "context/CanAddPinContext";
import SpaceTypePinMarkersState from "context/SpaceTypePinMarkersContext";
import { SpaceTypeSelectedLayerState } from "context/SpaceTypeSelectedLayerContext";
import SelectedVariantState from "context/SelectedVariantContext";
interface SpaceTypeDualViewContainerType {
  loading: boolean;
  viewDetails: SpaceTypeGetData;
}

const SpaceTypeDualViewContainer = ({
  loading,
  viewDetails,
}: SpaceTypeDualViewContainerType) => {
  const layerList = viewDetails?.layers;

  return (
    <SpaceTypeSelectedLayerState>
      <SelectedSpaceTypeProductState>
        <SelectedVariantState>
          <CanAddPinState>
            <SpaceTypePinMarkersState>
              <div className="dual-view tw-pt-12 tw-gap-12">
                <DualViewLeftSide
                  loading={loading}
                  viewDetails={viewDetails}
                  layerList={layerList}
                />
                <DualViewRightSide
                  viewDetails={viewDetails}
                  viewType={DiagramView.SPACE}
                />
              </div>
            </SpaceTypePinMarkersState>
          </CanAddPinState>
        </SelectedVariantState>
      </SelectedSpaceTypeProductState>
    </SpaceTypeSelectedLayerState>
  );
};

export default SpaceTypeDualViewContainer;
