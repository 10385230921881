/* eslint-disable @typescript-eslint/no-explicit-any */
import { Coordinate } from "models/Coordinate/coordinate.model";
import { LayerVariant } from "models/Layer/layer.model";
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSpaceTypeSelectedLayer } from "context/SpaceTypeSelectedLayerContext";

export interface SpaceTypePinMarkerType {
  coordinate: Coordinate;
  productId: string;
  productName: string;
}

interface SpaceTypePinMarkersContextType {
  pinMarkers: SpaceTypePinMarkerType[];
  addPinMarker?: (pinMarker: SpaceTypePinMarkerType) => void;
  removePinMarker?: (markerIdx: number) => void;
  removePinMarkerForProductId?: (productId: string) => void;
  updatePinMarkers?: (productList: LayerVariant[]) => void;
}

const initialValues = {
  pinMarkers: [],
};

// Create context
const SpaceTypePinMarkersContext =
  createContext<SpaceTypePinMarkersContextType>(initialValues);

// Add context provider
const SpaceTypePinMarkersState: FC = ({ children }) => {
  const [pinMarkers, setPinMarkers] = useState<SpaceTypePinMarkerType[]>([]);
  const { spaceTypeId } = useParams();
  const location = useLocation();
  const { layer } = useSpaceTypeSelectedLayer();

  const addPinMarker = (pinMarker: SpaceTypePinMarkerType) => {
    setPinMarkers([...pinMarkers, pinMarker]);
  };

  const removePinMarker = (markerIdx: number) => {
    setPinMarkers(pinMarkers?.filter((marker, idx) => idx !== markerIdx));
  };

  const removePinMarkerForProductId = (productId?: string) => {
    setPinMarkers(
      pinMarkers?.filter((marker) => marker?.productId !== productId)
    );
  };

  const updatePinMarkers = (productList: LayerVariant[]) => {
    if (!productList?.length) {
      setPinMarkers([]);
      return;
    }

    const pinnedProducts = productList.filter(
      (product: LayerVariant) =>
        !!product?.isPinned || !!(product as any).is_pinned
    );

    const pinMarkers = pinnedProducts.map((product: LayerVariant) => {
      return {
        productId: product?.id ?? "",
        productName: product?.title ?? "",
        coordinate: product?.coordinate ?? new Coordinate(),
      };
    });

    pinnedProducts?.length >= 0 ? setPinMarkers(pinMarkers) : setPinMarkers([]);
  };

  useEffect(() => {
    const pathName = location.pathname;

    if (pathName.includes("space-types") && spaceTypeId && layer?.products) {
      updatePinMarkers(layer.products);
    }
  }, [spaceTypeId, location, layer]);

  return (
    <SpaceTypePinMarkersContext.Provider
      value={{
        pinMarkers,
        addPinMarker,
        removePinMarker,
        removePinMarkerForProductId,
        updatePinMarkers,
      }}
    >
      {children}
    </SpaceTypePinMarkersContext.Provider>
  );
};

// Using the created context
export const useSpaceTypePinMarkers = () => {
  const context = useContext<SpaceTypePinMarkersContextType>(
    SpaceTypePinMarkersContext
  );
  if (!context) {
    throw new Error(
      "Space type pin markers context must be used within space type pin markers context"
    );
  }
  return {
    ...context,
  };
};

export default SpaceTypePinMarkersState;
