import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import axiosInstance from "interceptor/axiosInstance";
import { deserialize } from "serializr";
import { Col, Row, Skeleton } from "antd";
import { CONSTANTS } from "shared/constants";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import SpaceTypesGridView from "./SpaceTypesGridView";
import SpaceTypeGrid from "./SpaceTypeGrid";
import { QUERY_KEYS } from "shared/constants/queries";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { ButtonType } from "enums/buttonType.enum";
import Button from "shared/components/Button";
function getNumCategories(dimension: { width: number; height: number }) {
  if (dimension.width > CONSTANTS.LARGE_SCREEN_WIDTH) {
    return 11;
  }
  if (dimension.width > CONSTANTS.MEDIUM_SCREEN_WIDTH) {
    return 8;
  }
  return 5;
}

function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const SpaceTypes = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showAllCategories, setShowAllCategories] = React.useState(false);
  const [numCategories, setNumCategories] = React.useState(
    getNumCategories(getCurrentDimension())
  );
  const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

  const categoryId = searchParams.get("category");
  const subCategoryId = searchParams.get("subCategory");

  const { data: categories, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.SPACE_TYPE_CATEGORIES],
    queryFn: async () => {
      const { data } = await axiosInstance.get(ApiRoutes.SPACE_TYPE_CATEGORIES);
      return deserialize(
        SpaceTypeCategory,
        data["space_type_categories"]
      ) as SpaceTypeCategory[];
    },
    refetchOnWindowFocus: false,
  });

  const selectedCategory = categories?.find((cat) => cat.id === categoryId);
  const selectedSubCategory = selectedCategory?.subCategories?.find(
    (sub) => sub.id === subCategoryId
  );

  React.useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
      setNumCategories(getNumCategories(getCurrentDimension()));
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const displayedCategories = showAllCategories
    ? categories
    : categories?.slice(0, numCategories);

  if (categoryId && subCategoryId) {
    return (
      <SpaceTypeGrid
        category={selectedCategory}
        subCategory={selectedSubCategory}
      />
    );
  }

  const handleAddSpaceType = () => {
    navigate(NavigationRoutes.SPACE_TYPE_FORM);
  };

  return (
    <div>
      <div className="tw-w-[calc(100vw-15.8rem)] tw-flex tw-justify-between tw-bg-[#F5F5F5] tw-overflow-hidden tw-mb-[5.379rem] md:tw-px-[4rem] lg:tw-px-[9.9rem] tw-pt-[2.9rem] tw-pb-[0.3rem]">
        <div className="tw-flex-1">
          <Skeleton loading={isLoading}>
            <Row className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-flex-wrap">
                {displayedCategories?.map((category) => (
                  <Col
                    span={5}
                    key={category?.id}
                    className="tw-mr-[3.74rem] tw-mb-[2.3rem]"
                  >
                    <a
                      href={`#${category?.id}`}
                      className="tw-font-['DM_Sans'] tw-font-light tw-text-[#737373] hover:tw-text-[#1890ff] tw-tracking-wide tw-text-3xl"
                    >
                      {category?.name}
                    </a>
                  </Col>
                ))}
                {categories?.length && categories?.length > numCategories && (
                  <Col
                    span={5}
                    className="tw-mr-[3.74rem] tw-mb-[2.3rem] tw-text-primary tw-cursor-pointer tw-text-2xl"
                    onClick={() => setShowAllCategories(!showAllCategories)}
                  >
                    {showAllCategories ? "Show Less" : "View All"}
                  </Col>
                )}
              </div>
              <Button
                type={ButtonType.PRIMARY}
                onClick={handleAddSpaceType}
                className="tw-rounded-none tw-h-20"
              >
                Add Space Type
              </Button>
            </Row>
          </Skeleton>
        </div>
      </div>
      <SpaceTypesGridView categories={categories} isLoading={isLoading} />
    </div>
  );
};

export default SpaceTypes;
