import { alias, object, serializable } from "serializr";
import { SpaceTypeCategory } from "./spaceTypeCategory.model";
import { SpaceSubType } from "./spaceSubType.model";

export class SpaceTypeConfiguration {
  @serializable(object(SpaceTypeCategory))
  spaceTypeCategory = new SpaceTypeCategory();

  @serializable(alias("space_sub_type", object(SpaceSubType)))
  spaceSubType = new SpaceSubType();
}
