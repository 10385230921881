import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpaceParams } from "shared/types/routeParams";
import { Form, Formik, FormikConfig } from "formik";
import layerValidation from "./layerValidation";
import Input from "shared/components/Input";
import TextArea from "shared/components/TextArea";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import useRedirect from "shared/hooks/useRedirect";
import UploadInput from "shared/components/UploadInput";
import { Space } from "antd";
import Label from "shared/components/Label";
import LayerService from "services/LayerService/layer.service";
import { Layer } from "models/Layer/layer.model";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import { Back, Delete, ViewDescription } from "shared/icons";
import "./layerForm.scss";
import Spinner from "shared/components/Spinner";
import { useSelectedLayerId } from "context/SelectedLayerContext";
import AttachmentPreview from "shared/components/AttachmentPreview";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import {
  useSpaceTypeLayer,
  useSpaceTypeLayerDetails,
} from "shared/hooks/useSpaceTypeLayer";
// import { useHistory } from "react-router-dom";

const LayerForm = () => {
  const { prototypeId, planId, spaceId, zoneId } = useParams<SpaceParams>();
  const { spaceTypeId } = useParams();
  const { layerId } = useParams();
  const { updateLayerDetails } = useSelectedLayerId();
  const {
    loading: layerLoading,
    createLayer,
    layer,
    getLayer,
    updateLayer,
    deleteLayer,
  } = LayerService();

  const { redirectToSpaceView, goBack } = useRedirect();

  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    subTitle: "",
    title: <></>,
    deleteText: "",
    hideTrash: true,
  });

  const handleSpaceViewRedirect = () => {
    goBack();
  };

  const { updateSelectedLayerId } = useSelectedLayerId();

  const { createSpaceTypeLayer, updateSpaceTypeLayer, deleteSpaceTypeLayer } =
    useSpaceTypeLayer(spaceTypeId);

  const handleSubmit: FormikConfig<Layer>["onSubmit"] = async (layer) => {
    if (spaceTypeId) {
      if (layer?.id) {
        const updatedLayer = await updateSpaceTypeLayer.mutateAsync(layer);
        updatedLayer && updateLayerDetails?.(updatedLayer);
      } else {
        const newLayer = await createSpaceTypeLayer.mutateAsync(layer);
        updateSelectedLayerId?.(newLayer?.id);
      }
    } else {
      if (!prototypeId || !planId || !spaceId) return;

      if (layer?.id) {
        const updatedLayer = await updateLayer(
          prototypeId,
          planId,
          spaceId,
          layer?.id,
          layer
        );
        updatedLayer && updateLayerDetails?.(updatedLayer);
      } else {
        const updatedLayer = await createLayer(
          prototypeId,
          planId,
          spaceId,
          layer
        );
        updateSelectedLayerId?.(updatedLayer?.id);
      }
    }
    handleSpaceViewRedirect();
  };

  const handleUpdate = async (
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    await submitForm();
    setModalProps({ ...modalProps, open: false });
  };

  const handleDelete = async () => {
    if (spaceTypeId && layerId) {
      await deleteSpaceTypeLayer.mutateAsync(layerId);
    } else {
      prototypeId &&
        planId &&
        spaceId &&
        layerId &&
        (await deleteLayer(prototypeId, planId, spaceId, layerId));
    }
    setModalProps({ ...modalProps, open: false });
    updateSelectedLayerId?.(undefined);

    if (spaceTypeId) {
      // Handle space type redirect
      goBack();
    } else {
      prototypeId &&
        planId &&
        zoneId &&
        spaceId &&
        redirectToSpaceView(prototypeId, planId, zoneId, spaceId);
    }
  };

  const { data: spaceTypeLayer } = useSpaceTypeLayerDetails(
    spaceTypeId,
    layerId
  );

  const onUpdateClicked = (
    isValid: boolean,
    submitForm: any,
    isSubmitting: boolean
  ) => {
    if (layer?.id || spaceTypeLayer?.id) {
      setModalProps({
        hideTrash: true,
        onDelete: () => {
          if (isValid) {
            handleUpdate(submitForm);
          } else {
            setModalProps({
              ...modalProps,
              open: false,
            });
            Notification({
              message: "Invalid data provided",
              type: NotificationTypes.ERROR,
            });
          }
        },
        onCancel: () => {
          setModalProps({ ...modalProps, open: false });
        },
        open: true,
        deleteText: "Update",
        title: "Are you sure to update?",
        loading: isSubmitting,
        width: 340,
      });
    }
  };

  useEffect(() => {
    if (spaceTypeId && layerId) {
      // Layer data will be handled by react-query
      return;
    }
    if (prototypeId && planId && spaceId && layerId) {
      getLayer(prototypeId, planId, spaceId, layerId);
    }
  }, [prototypeId, planId, spaceId, layerId, spaceTypeId]);

  return (
    <Spinner
      loading={
        layerLoading ||
        createSpaceTypeLayer.isLoading ||
        updateSpaceTypeLayer.isLoading ||
        deleteSpaceTypeLayer.isLoading
      }
    >
      <div className="plan-form">
        <Formik
          initialValues={
            spaceTypeId
              ? spaceTypeLayer ?? {
                  title: "",
                  description: "",
                  diagram: undefined,
                }
              : layer ?? {
                  title: "",
                  description: "",
                  diagram: undefined,
                }
          }
          onSubmit={handleSubmit}
          validationSchema={layerValidation}
          enableReinitialize
        >
          {({ isSubmitting, values, setFieldValue, submitForm, isValid }) => (
            <>
              <Form>
                <div className="plan-form__container">
                  <div className="plan-form__container__buttons">
                    <div
                      className="plan-form__container__buttons__back"
                      onClick={() => {
                        // prototypeId &&
                        //   planId &&
                        //   spaceId &&
                        //   zoneId &&
                        //   redirectToSpaceView(prototypeId, planId, zoneId, spaceId);
                        window.history.go(-1);
                      }}
                    >
                      <span className="icon-container">
                        <Back className="icon--back" />
                        <span className="text-capitalize">back</span>
                      </span>
                    </div>
                    <div className="plan-form__container__buttons__form-action">
                      {layer?.id && (
                        <Button
                          type={ButtonType.TEXT}
                          icon={<Delete className="icon-delete" />}
                          className="button--delete"
                          onClick={() => {
                            setModalProps({
                              open: true,
                              hideTrash: false,
                              onDelete: handleDelete,
                              onCancel: () => {
                                setModalProps({ ...modalProps, open: false });
                              },
                              deleteText: "Delete",
                              subTitle:
                                "This layer will be permanently deleted.",
                              loading: layerLoading,
                            });
                          }}
                        >
                          Delete Layer
                        </Button>
                      )}
                      <Button
                        type={ButtonType.DEFAULT}
                        onClick={() => {
                          setModalProps({
                            hideTrash: true,
                            onDelete: () => {
                              prototypeId &&
                                planId &&
                                spaceId &&
                                zoneId &&
                                redirectToSpaceView(
                                  prototypeId,
                                  planId,
                                  zoneId,
                                  spaceId
                                );
                            },
                            onCancel: () => {
                              setModalProps({ ...modalProps, open: false });
                            },
                            open: true,
                            deleteText: "Discard",
                            title: "Are you sure to discard?",
                            subTitle: "The changes made will not be saved.",
                            width: 340,
                          });
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        htmlType={
                          layer?.id || spaceTypeLayer?.id ? undefined : "submit"
                        }
                        loading={isSubmitting}
                        onClick={() =>
                          onUpdateClicked(isValid, submitForm, isSubmitting)
                        }
                        type={ButtonType.PRIMARY}
                      >
                        {layer?.id || spaceTypeLayer?.id ? "Update" : "Create"}
                      </Button>
                    </div>
                  </div>
                  <div className="plan-form__container__img">
                    <Input.Formik
                      name="title"
                      placeholder="Enter Layer Name"
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue("title", trimmedValue);
                      }}
                    />
                  </div>
                  <div className="plan-form__description__container">
                    <div className="plan-form__description__title">
                      <ViewDescription className="icon-view-description" />
                      <span>Layer Description</span>
                    </div>
                    <TextArea.Formik
                      name="description"
                      rows={10}
                      className="plan-form__description__input"
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setFieldValue("description", trimmedValue);
                      }}
                    />
                  </div>
                  <div className="plan-form__description__diagram">
                    {values?.diagram?.id ? (
                      <Space direction="vertical">
                        <Label label="Layer Diagram" />
                        <AttachmentPreview
                          key={values?.diagram?.id}
                          type="delete"
                          attachment={values?.diagram}
                          onClick={() => setFieldValue("diagram", undefined)}
                        />
                      </Space>
                    ) : (
                      <UploadInput.Formik
                        primaryTheme
                        className="plan-form__container__diagram"
                        label="Layer Diagram"
                        name="diagram"
                        note="Supported formats: JPG, JPEG & PNG and < 50MB"
                        title="Upload layer diagram"
                        options={{
                          restrictions: {
                            maxFileSize: 50 * 1024 * 1024, // 50MB
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </Form>

              {/* Confirmation modal */}
              <DeleteModal {...modalProps} />
            </>
          )}
        </Formik>
      </div>
    </Spinner>
  );
};
export default LayerForm;
