import React, { useEffect } from "react";
import "./prototypeCombined.scss";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { Space } from "models/Space/space.model";
import { PrototypeWithPlans } from "models/PrototypeWithPlans/prototypeWithPlans";
import { Plan } from "models/Plans/plans.model";
import DualViewLeftSide from "./DualViewLeftSide";
import DualViewRightSide from "./DualViewRightSide";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { DiagramView } from "enums/diagramView";
import { Layer } from "models/Layer/layer.model";
import { useSpaceTypeCategories } from "shared/hooks/useSpaceTypeCategories";

//Prototype View & Plan View (dual) - Plan listing (level1List) and space listing (level2List)
//Space View & layer view - layer listing (level1List) and products listing (level2List)

interface DualViewContainerType {
  viewType: DiagramView.PROTOTYPE | DiagramView.SPACE | DiagramView.ZONE;
  breadCrumbItemList?: ItemType[];
  loading: boolean;
  subViewListingService: () => void;
  viewDetails: PrototypeWithPlans | Space;
  subViewList?: Plan[] | Layer[];
}

export interface selectedSpaceType {
  id: string;
  coordinates?: Coordinate;
}

export interface selectedZoneType {
  id: string;
  coordinates?: Coordinate;
}

const DualViewContainer = ({
  viewType,
  breadCrumbItemList,
  loading,
  subViewListingService,
  viewDetails,
  subViewList,
}: DualViewContainerType) => {
  const { categories, isLoading: categoriesLoading } = useSpaceTypeCategories();

  useEffect(() => {
    subViewListingService();
  }, []);

  return (
    <div className="dual-view pt-10">
      <DualViewLeftSide
        viewType={viewType}
        breadCrumbItemList={breadCrumbItemList}
        loading={loading || categoriesLoading}
        viewDetails={viewDetails}
        subViewList={subViewList}
        categories={categories}
      />
      <DualViewRightSide viewDetails={viewDetails} viewType={viewType} />
    </div>
  );
};

export default DualViewContainer;
