import { useMutation } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { Layer } from "models/Layer/layer.model";
import { LayerVariant } from "models/Layer/layer.model";
import { Coordinate } from "models/Coordinate/coordinate.model";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import { deserialize } from "serializr";

export const useSpaceTypeLayerProduct = () => {
  const addProductToSpaceTypeLayer = useMutation({
    mutationFn: async ({
      spaceTypeId,
      layerId,
      selectedVariantId,
    }: {
      spaceTypeId: string;
      layerId: string;
      selectedVariantId: string;
    }) => {
      try {
        const endpoint = generatePath(ApiRoutes.SPACE_TYPE_LAYER_PRODUCT, {
          spaceTypeId,
          layerId,
        });
        const payload = {
          layer: {
            products: [{ variant: selectedVariantId }],
          },
        };
        const { data } = await axiosInstance.put(endpoint, payload);
        Notification({
          message: "Product added to layer",
          type: NotificationTypes.SUCCESS,
        });
        return data.layer as Layer;
      } catch (err) {
        Notification({
          message: (err as Error)?.message || "Unable to add product to layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  const pinProductToSpaceTypeLayer = useMutation({
    mutationFn: async ({
      spaceTypeId,
      layerId,
      layerVariantId,
      coordinate,
      pin = "add",
    }: {
      spaceTypeId: string;
      layerId: string;
      layerVariantId: string;
      coordinate: Coordinate;
      pin?: "add" | "remove";
    }) => {
      try {
        const endpoint = generatePath(ApiRoutes.SPACE_TYPE_LAYER_PRODUCT_PIN, {
          spaceTypeId,
          spaceTypeLayerId: layerId,
          productId: layerVariantId,
        });

        const payload = {
          coordinate,
          pin,
        };

        const { data } = await axiosInstance.put(endpoint, payload);

        Notification({
          message: `${pin === "remove" ? "Unpinned" : "Pinned"} product ${
            pin === "remove" ? "from" : "to"
          } the layer`,
          type: NotificationTypes.SUCCESS,
        });

        return deserialize(LayerVariant, data["product"]);
      } catch (err) {
        Notification({
          message: (err as Error)?.message || "Unable to pin product to layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  const removeProductFromSpaceTypeLayer = useMutation({
    mutationFn: async ({
      spaceTypeId,
      layerId,
      layerVariantId,
    }: {
      spaceTypeId: string;
      layerId: string;
      layerVariantId: string;
    }) => {
      try {
        const endpoint = generatePath(
          ApiRoutes.SPACE_TYPE_LAYER_PRODUCT_REMOVE,
          {
            spaceTypeId,
            spaceTypeLayerId: layerId,
            productId: layerVariantId,
          }
        );
        const res = await axiosInstance.delete(endpoint);

        if (res.status === 204) {
          Notification({
            message: "Product removed from layer",
            type: NotificationTypes.SUCCESS,
          });
          return true;
        }
        return false;
      } catch (err) {
        Notification({
          message:
            (err as Error)?.message || "Unable to remove product from layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  const replaceProductInSpaceTypeLayer = useMutation({
    mutationFn: async ({
      spaceTypeId,
      layerId,
      layerVariantId,
      currentLayerVariantId,
    }: {
      spaceTypeId: string;
      layerId: string;
      layerVariantId: string;
      currentLayerVariantId: string;
    }) => {
      try {
        const endpoint = generatePath(
          ApiRoutes.SPACE_TYPE_LAYER_PRODUCT_REPLACE,
          {
            spaceTypeId,
            spaceTypeLayerId: layerId,
            productId: currentLayerVariantId,
          }
        );

        const payload = {
          layer: {
            products: [{ variant: layerVariantId }],
          },
        };

        const res = await axiosInstance.put(endpoint, payload);

        if (res.status === 200) {
          Notification({
            message: "Product replaced in layer",
            type: NotificationTypes.SUCCESS,
          });
          return res.data.layer_variant as LayerVariant;
        }
      } catch (err) {
        Notification({
          message:
            (err as Error)?.message || "Unable to replace product in layer",
          type: NotificationTypes.ERROR,
        });
        throw err;
      }
    },
  });

  return {
    addProductToSpaceTypeLayer,
    pinProductToSpaceTypeLayer,
    removeProductFromSpaceTypeLayer,
    replaceProductInSpaceTypeLayer,
  };
};
