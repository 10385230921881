import { LayerError } from "shared/constants/layer";
import getObjectIdSchema from "shared/utils/getObjectIdValidation";
import * as Yup from "yup";

export default Yup.object().shape({
  title: Yup.string()
    .required(LayerError.TITLE_REQUIRED)
    .max(120, LayerError.TITLE_LENGTH),
  description: Yup.string().required(LayerError.DESCRIPTION_REQUIRED),
  diagram: getObjectIdSchema(true, LayerError.DIAGRAM_REQUIRED).required(
    LayerError.DIAGRAM_REQUIRED
  ),
});
