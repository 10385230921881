import React, { createContext, FC, useContext, useState } from "react";
import { LayerVariant } from "models/Layer/layer.model";

interface SelectedSpaceTypeProductContextType {
  selectedProduct?: LayerVariant;
  isViewingProduct: boolean;
  handleSetSelectedProduct?: (product?: LayerVariant) => void;
  handleSetIsViewingProduct?: (isViewing: boolean) => void;
}

const SelectedSpaceTypeProductContext =
  createContext<SelectedSpaceTypeProductContextType>({
    isViewingProduct: false,
  });

export const useSelectedSpaceTypeProduct = () =>
  useContext(SelectedSpaceTypeProductContext);

export const SelectedSpaceTypeProductState: FC = ({ children }) => {
  const [selectedProduct, setSelectedProduct] = useState<LayerVariant>();
  const [isViewingProduct, setIsViewingProduct] = useState(false);

  const handleSetSelectedProduct = (product?: LayerVariant) => {
    setSelectedProduct(product);
  };

  const handleSetIsViewingProduct = (isViewing: boolean) => {
    setIsViewingProduct(isViewing);
  };

  return (
    <SelectedSpaceTypeProductContext.Provider
      value={{
        selectedProduct,
        isViewingProduct,
        handleSetSelectedProduct,
        handleSetIsViewingProduct,
      }}
    >
      {children}
    </SelectedSpaceTypeProductContext.Provider>
  );
};
