import React, { createContext, FC, useContext, useState } from "react";
import { Layer } from "models/Layer/layer.model";

interface SpaceTypeSelectedLayerType {
  selectedLayerId?: string;
  layer?: Layer;
  updateSelectedLayerId?: (layerId?: string) => void;
  updateLayerDetails?: (layer: Layer) => void;
}

const SpaceTypeSelectedLayerContext = createContext<SpaceTypeSelectedLayerType>(
  {}
);

export const SpaceTypeSelectedLayerState: FC = ({ children }) => {
  const [selectedLayerId, setSelectedLayerId] = useState<string>();
  const [layer, setLayer] = useState<Layer>();

  const updateSelectedLayerId = (layerId?: string) => {
    setSelectedLayerId(layerId);
  };

  const updateLayerDetails = (newLayer: Layer) => {
    setLayer(newLayer);
  };

  return (
    <SpaceTypeSelectedLayerContext.Provider
      value={{
        selectedLayerId,
        layer,
        updateSelectedLayerId,
        updateLayerDetails,
      }}
    >
      {children}
    </SpaceTypeSelectedLayerContext.Provider>
  );
};

export const useSpaceTypeSelectedLayer = () => {
  const context = useContext(SpaceTypeSelectedLayerContext);
  if (!context) {
    throw new Error(
      "useSpaceTypeSelectedLayer must be used within SpaceTypeSelectedLayerState"
    );
  }
  return { ...context };
};

export default SpaceTypeSelectedLayerState;
