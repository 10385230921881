import React from "react";
import { Category } from "models/Category/category.model";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { Type } from "models/Type/type.model";
import { Form, Formik } from "formik";
import Input from "shared/components/Input";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";

import "./addItem.scss";
import { Tooltip } from "antd";
import UploadInput from "shared/components/UploadInput";
import UploadedImage from "shared/components/UploadedImage";
import { addItemValidationSchema } from "./addItem.validation";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { SpaceTypeSubCategory } from "models/SpaceType/spaceTypeSubCategory.model";

interface AddItemProps<
  T extends
    | Category
    | SubCategory
    | Type
    | SpaceTypeCategory
    | SpaceTypeSubCategory
> {
  item: T;
  onSubmit: (item: T) => void;
  onCancel: () => void;
}

const AddItem = <
  T extends
    | Category
    | SubCategory
    | Type
    | SpaceTypeCategory
    | SpaceTypeSubCategory
>({
  item,
  onSubmit,
  onCancel,
}: AddItemProps<T>) => {
  const isCategory = item instanceof Category;

  const isSubCategory = item instanceof SubCategory;

  const isType = item instanceof Type;

  const isSpaceTypeCategory = item instanceof SpaceTypeCategory;
  const isSpaceTypeSubCategory = item instanceof SpaceTypeSubCategory;

  const isSpaceTypeConfig = isSpaceTypeCategory || isSpaceTypeSubCategory;

  const label = isCategory
    ? "Category"
    : isSubCategory
    ? "Sub Category"
    : isType
    ? "Type"
    : "";

  const validationSchema = addItemValidationSchema;

  return (
    <div className="add-item">
      <Formik
        initialValues={{ ...item }}
        onSubmit={(values) => {
          const trimmedValues = {
            ...values,
            name: values.name?.trim(),
          };
          onSubmit(trimmedValues);
        }}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, submitForm, resetForm, setValues }) => {
          return (
            <Form className="add-item__form">
              <div className="add-item__form__label">{label}</div>
              <div className="display-flex">
                <div className="add-item__form__img">
                  {(isSubCategory || isType) && !isSpaceTypeConfig ? (
                    (values as SubCategory)?.image ? (
                      <div className="multiple-variant__image__container">
                        <UploadedImage
                          expandable
                          width={44}
                          hideRemoveTextOnOverlay
                          onDelete={() => {
                            const tempVal = values;
                            (tempVal as SubCategory).image = undefined;
                            setValues(tempVal);
                          }}
                          src={(values as SubCategory)?.image?.url}
                        />
                      </div>
                    ) : (
                      <Tooltip title="Supported formats: JPG, JPEG & PNG in 1:1 ratio and < 10 MB">
                        <UploadInput.Formik
                          name={"image"}
                          width={"20px"}
                          height={"44px"}
                          aspectRatio={1}
                        />
                      </Tooltip>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <Input.Formik name="name" placeholder="Enter" />
              </div>
              <div className="add-item__form__buttons">
                <Button
                  type={ButtonType.TEXT}
                  htmlType="reset"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  loading={isSubmitting}
                  disabled={!values.name?.trim()}
                  type={ButtonType.LINK}
                  onClick={() => {
                    try {
                      submitForm().then(() => resetForm());
                    } catch (error) {
                      console.log("error", error);
                    }
                  }}
                >
                  {item?.id ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddItem;
