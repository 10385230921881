import React from "react";
import "./space-type-marker.scss";
import { Eye, ProductPin, RemovePin } from "shared/icons";
import { Popover, Tooltip } from "antd";
import { useSelectedSpaceTypeProduct } from "context/SelectedSpaceTypeProductContext";
import { useSpaceTypeSelectedLayer } from "context/SpaceTypeSelectedLayerContext";
import { Space } from "models/Space/space.model";
import { useSpaceTypePinMarkers } from "context/SpaceTypePinMarkersContext";
import { useSpaceTypeLayerProduct } from "shared/hooks/useSpaceTypeLayerProduct";
import { useParams } from "react-router-dom";

interface SpaceTypeMarkerProps {
  dataset: number[][];
  noiseIndex: number;
  space?: Space;
  selectedMarker?: string;
  setSelectedMarker?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SpaceTypeMarker = ({
  space,
  selectedMarker,
  setSelectedMarker,
  noiseIndex,
}: SpaceTypeMarkerProps) => {
  const { spaceTypeId } = useParams();
  const {
    selectedProduct,
    handleSetSelectedProduct,
    handleSetIsViewingProduct,
  } = useSelectedSpaceTypeProduct();

  const { selectedLayerId, layer } = useSpaceTypeSelectedLayer();

  const { removePinMarkerForProductId } = useSpaceTypePinMarkers();
  const { pinProductToSpaceTypeLayer } = useSpaceTypeLayerProduct();

  const handleRemovePin = async () => {
    if (spaceTypeId && selectedLayerId && space?.id) {
      try {
        await pinProductToSpaceTypeLayer.mutateAsync({
          spaceTypeId,
          layerId: selectedLayerId,
          layerVariantId: space.id,
          coordinate: { x: 1, y: 1 },
          pin: "remove",
        });
        removePinMarkerForProductId?.(space.id);
      } catch (error) {
        // Error handling is done in the mutation
        return;
      }
    }
  };

  const content = (
    <div className="pin-marker-details">
      <div className="title">{space?.title}</div>
      <div>
        <Tooltip title="Remove Pin">
          <RemovePin className="icon-pin" onClick={handleRemovePin} />
        </Tooltip>
      </div>
    </div>
  );

  const isSelected = selectedProduct?.id === space?.id;

  return (
    <Popover content={content} className="space-type-marker" placement="bottom">
      <span
        className="space-type-marker"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setSelectedMarker?.(space?.id);
        }}
      >
        <div
          className={`space-type-marker__outer ${isSelected && "color-black"}`}
          id={space?.id}
        >
          <>
            <Tooltip title="View Product">
              <Eye
                className="icon-pin icon-eye-hover"
                onClick={() => {
                  if (space?.id) {
                    const product = layer?.products?.find(
                      (product) => product.id === space.id
                    );
                    if (product) {
                      handleSetSelectedProduct?.(product);
                      handleSetIsViewingProduct?.(true);
                    }
                  }
                }}
              />
            </Tooltip>
            <ProductPin className="icon-pin" />
          </>
        </div>
      </span>
    </Popover>
  );
};

export default SpaceTypeMarker;
