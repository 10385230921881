import React, { useRef } from "react";
import "../filteredProducts.scss";
import { Carousel, Col } from "antd";
import { Product } from "models/Product/product.model";
import { CarouselRef } from "antd/es/carousel";
import useRedirect from "shared/hooks/useRedirect";
import { usePicklistToggle } from "context/PicklistToggleContext";
import { AddToPicklist } from "shared/icons";
import { Variant } from "models/Variant/variant.model";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
import { userHasAccess } from "utils";
import { Module } from "enums/module.enum";
import { Method } from "enums/method.enum";
import { priceUnitTypesMap } from "shared/constants/products";

const ProductCard = ({ product }: { product: Product }) => {
  const carouselRef = useRef<CarouselRef>(null);
  const { redirectToViewProduct } = useRedirect();
  const {
    togglePicklistOn,
    setPicklistSelectedProduct,
    setPicklistSelectedvariant,
  } = usePicklistToggle();

  const picklistDrawerOpen = (
    e: React.BaseSyntheticEvent,
    product: Product,
    variant: Variant
  ) => {
    e.stopPropagation();
    e.preventDefault();
    togglePicklistOn?.();
    setPicklistSelectedProduct?.(product);
    setPicklistSelectedvariant?.(variant);
  };

  return (
    <Col
      className="gutter-row cursor-pointer product__carousel"
      key={product?.id}
      span={6}
    >
      <div
        className="gutter-box"
        onClick={() => {
          redirectToViewProduct(product?.id);
        }}
      >
        <Carousel dots={false} ref={carouselRef}>
          {product?.variants?.map((variant, id) => (
            <>
              <div className="products-grid__carousel">
                <LazyLoadedImage
                  src={
                    variant?.image?.url || variant?.image?.thumbnailUrl || ""
                  }
                  alt={variant?.name}
                  active={
                    !!variant?.image?.url || !!variant?.image?.thumbnailUrl
                  }
                />
                <div className="product-count current-variant">
                  <p>
                    {id + 1}/{product?.variants?.length}
                  </p>
                </div>
                <div className="product-count all-variant">
                  <p>{product?.variants?.length}&nbsp;Items</p>
                </div>
                {product?.status !== "active" ? (
                  <div className="product-status">
                    <p>
                      <span className="middot"></span>&nbsp;inactive
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <div className="products-grid__carousel__text">
                  {userHasAccess(Module.PICKLIST, Method.EDIT) && (
                    <div
                      className="pd-t-b add__to__picklist"
                      onClick={(e) => {
                        picklistDrawerOpen(e, product, variant);
                      }}
                    >
                      <AddToPicklist className="add__to__picklist__icon" />
                      <p>Add to Picklist</p>
                    </div>
                  )}
                  <div className="pd-t-b product__carousel__action">
                    <span
                      className="left-abs"
                      onClick={(e) => {
                        e.stopPropagation();
                        carouselRef?.current?.prev();
                      }}
                    >
                      {"<"}
                    </span>{" "}
                    {variant?.name}
                    <span
                      className="right-abs"
                      onClick={(e) => {
                        e.stopPropagation();
                        carouselRef?.current?.next();
                      }}
                    >
                      {">"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="products-grid__vendor">
                {product?.vendor?.name}
              </div>
              <div className="products-grid__heading">{product?.name}</div>
              <div className="products-grid__price">
                $ {variant?.cost?.value}{" "}
                {variant?.cost?.type
                  ? `/ ${priceUnitTypesMap[variant.cost.type]}`
                  : ""}
              </div>
            </>
          ))}
        </Carousel>
      </div>
    </Col>
  );
};

export default ProductCard;
