import { useSelectedLayerId } from "context/SelectedLayerContext";
import { useSelectedSpaceTypeProduct } from "context/SelectedSpaceTypeProductContext";
import { useSpaceTypeSelectedLayer } from "context/SpaceTypeSelectedLayerContext";
import { Coordinate } from "models/Coordinate/coordinate.model";
import React, { createContext, FC, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import LayerService from "services/LayerService/layer.service";
import { useSpaceTypeLayerProduct } from "shared/hooks/useSpaceTypeLayerProduct";

interface SelectedVariantModel {
  id: string;
  coordinates?: Coordinate;
}

interface SelectedVariantType {
  selectedVariant?: SelectedVariantModel;
  updateSelectedVariantId?: (variant?: SelectedVariantModel) => void;
  handlePinProductToLayer?: (coordinate: Coordinate) => void;
  handleUnpinProductFromLayer?: (selectedLayerVariantId: string) => void;
}

const SelectedVariantContext = createContext<SelectedVariantType>({});

const SelectedVariantState: FC<{ viewId?: string }> = ({
  viewId,
  children,
}) => {
  const [selectedVariant, setSelectedVariantId] =
    useState<SelectedVariantModel>();
  const { selectedLayerId, layer, updateLayerDetails } = useSelectedLayerId();
  const { prototypeId, planId, spaceId, spaceTypeId } = useParams();
  const { selectedProduct } = useSelectedSpaceTypeProduct();
  const { pinProductToLayer, updatingPin } = LayerService();
  const { pinProductToSpaceTypeLayer } = useSpaceTypeLayerProduct();
  const { selectedLayerId: spaceTypeSelectedLayerId } =
    useSpaceTypeSelectedLayer();

  const updateSelectedVariantId = (variant?: SelectedVariantModel) => {
    setSelectedVariantId(variant);
  };

  const handlePinProductToLayer = async (coordinate: Coordinate) => {
    if (
      spaceTypeId &&
      spaceTypeSelectedLayerId &&
      selectedProduct?.id &&
      !updatingPin
    ) {
      try {
        const updatedProduct = await pinProductToSpaceTypeLayer.mutateAsync({
          spaceTypeId,
          layerId: spaceTypeSelectedLayerId,
          layerVariantId: selectedProduct.id,
          coordinate,
          pin: "add",
        });

        if (layer?.products && updatedProduct) {
          const updatedProducts = [...layer.products];
          const selectedProductIdx = updatedProducts.findIndex(
            (product) => product?.id === updatedProduct?.id
          );
          updatedProducts?.splice(selectedProductIdx, 1, updatedProduct);
          updateLayerDetails?.({ ...layer, products: updatedProducts });
        }
        return;
      } catch (error) {
        // Error handling is done in the mutation
        return;
      }
    }

    // Handle prototype/plan/space product pinning
    if (
      prototypeId &&
      planId &&
      spaceId &&
      selectedLayerId &&
      selectedVariant?.id &&
      !updatingPin
    ) {
      const updatedProduct = await pinProductToLayer(
        prototypeId,
        planId,
        spaceId,
        selectedLayerId,
        selectedVariant.id,
        coordinate
      );

      if (layer?.products && updatedProduct) {
        const updatedProducts = [...layer.products];
        const selectedProductIdx = updatedProducts.findIndex(
          (product) => product?.id === updatedProduct?.id
        );
        updatedProducts?.splice(selectedProductIdx, 1, updatedProduct);
        updateLayerDetails?.({ ...layer, products: updatedProducts });
      }
    }
  };

  const handleUnpinProductFromLayer = async (
    selectedLayerVariantId: string
  ) => {
    // Handle space type product unpinning
    if (
      spaceTypeId &&
      spaceTypeSelectedLayerId &&
      selectedProduct?.id &&
      !updatingPin
    ) {
      try {
        const updatedProduct = await pinProductToSpaceTypeLayer.mutateAsync({
          spaceTypeId,
          layerId: spaceTypeSelectedLayerId,
          layerVariantId: selectedProduct.id,
          coordinate: { x: 1, y: 1 },
          pin: "remove",
        });

        if (layer?.products && updatedProduct) {
          const updatedProducts = [...layer.products];
          const selectedProductIdx = updatedProducts.findIndex(
            (product) => product?.id === updatedProduct?.id
          );
          updatedProducts?.splice(selectedProductIdx, 1, updatedProduct);
          updateLayerDetails?.({ ...layer, products: updatedProducts });
        }
        return;
      } catch (error) {
        // Error handling is done in the mutation
        return;
      }
    }

    // Handle prototype/plan/space product unpinning
    if (
      prototypeId &&
      planId &&
      spaceId &&
      selectedLayerId &&
      selectedLayerVariantId &&
      !updatingPin
    ) {
      const updatedProduct = await pinProductToLayer(
        prototypeId,
        planId,
        spaceId,
        selectedLayerId,
        selectedLayerVariantId,
        { x: 1, y: 1 },
        "remove"
      );

      if (layer?.products && updatedProduct) {
        const updatedProducts = [...layer.products];
        const selectedProductIdx = updatedProducts.findIndex(
          (product) => product?.id === updatedProduct?.id
        );
        updatedProducts?.splice(selectedProductIdx, 1, updatedProduct);
        updateLayerDetails?.({ ...layer, products: updatedProducts });
      }
    }
  };

  return (
    <SelectedVariantContext.Provider
      value={{
        selectedVariant,
        updateSelectedVariantId,
        handlePinProductToLayer,
        handleUnpinProductFromLayer,
      }}
    >
      {children}
    </SelectedVariantContext.Provider>
  );
};

export const useSelectedVariantId = () => {
  const context = useContext<SelectedVariantType>(SelectedVariantContext);
  if (!context) {
    throw new Error(
      "selectedVariant context must be used within selectedVariant context provider"
    );
  }
  return { ...context };
};

export default SelectedVariantState;

// WHY we have two different contexts SELECTED VARIANT AND OPENED Layer variants
// Selected variant has the id which is the layer variant selected inorder to pin it or unpin it to the layer diagram
// whereas Opened layer variant id is the layer variant selected when we click on viewing that variant variant
