import { useQuery } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { QUERY_KEYS } from "shared/constants/queries";
import { deserialize } from "serializr";

export const useSpaceTypeCategories = () => {
  const { data: categories, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.SPACE_TYPE_CATEGORIES],
    queryFn: async () => {
      const { data } = await axiosInstance.get(ApiRoutes.SPACE_TYPE_CATEGORIES);
      return deserialize(
        SpaceTypeCategory,
        data["space_type_categories"]
      ) as SpaceTypeCategory[];
    },
    refetchOnWindowFocus: false,
  });

  return {
    categories,
    isLoading,
  };
};
