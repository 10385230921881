import { serializable, list, object } from "serializr";
import { SpaceTypeSubCategory } from "./spaceTypeSubCategory.model";

export class SpaceTypeCategory {
  @serializable
  id?: string;

  @serializable
  _id?: string;
  @serializable
  name?: string;

  @serializable(list(object(SpaceTypeSubCategory)))
  subCategories?: SpaceTypeSubCategory[];
}
