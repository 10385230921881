import { ApiBasePath } from "enums/apiBasePath";

export const NavigationRoutes = {
  LOGIN: "/auth/login",
  PRODUCTS: "/products",
  PRODUCTS_SEARCH: `/products?page=1`,

  // PROTOTYPE
  PROTOTYPE: "/prototypes",
  VIEW_PROTOTYPE: ApiBasePath.PROTOTYPE,
  VIEW_ZONE: ApiBasePath.ZONE,
  VIEW_SPACE: ApiBasePath.SPACE,
  ADD_PROTOTYPE: "/prototypes/prototype-form",

  USERS: "/users",
  ADD_USER: "/users/user-form",
  EDIT_USER: "/users/:userId/user-form",
  SETTINGS: "/settings",
  NOTIFICATION: "/notification",

  // SPACE TYPES
  SPACE_TYPES: "/space-types",
  SPACE_TYPE_FORM: "/space-types/space-type-form",
  VIEW_SPACE_TYPE: "/space-types/:spaceTypeId",
  UPDATE_SPACE_TYPE: "/space-types/space-type-form/:spaceTypeId",
  SPACE_TYPE_ADD_LAYER: "/space-types/:spaceTypeId/add-layer",
  SPACE_TYPE_EDIT_LAYER: "/space-types/:spaceTypeId/add-layer/:layerId",

  SPACE_TYPE_PRODUCT_LIST:
    "/space-types/:spaceTypeId/layer/:spaceTypeLayerId/product-list",
  SPACE_TYPE_PRODUCT_LIST_REPLACE:
    "/space-types/:spaceTypeId/layer/:spaceTypeLayerId/product-list/:layerVariantId",
  // PICK LIST
  PICKLIST: "/picklist",
  NO_ACCESS: "/no-access",
};

export const AppRoutes = {
  AUTH: "/auth/*",
  REGISTER: "/register",
  LOGIN: "/login",
  APP_COMPONENTS: "/app-components",
  HOME: "/",
  ADD_PRODUCT: "/product-form",
  EDIT_PRODUCT: "/products/:productId/product-form",
  VIEW_PRODUCT: "/products/:productId/view",

  // PROTOTYPE
  UPDATE_PROTOTYPE: "/prototypes/prototype-form/:prototypeId",

  // PLAN
  ADD_PLAN: ApiBasePath.PROTOTYPE + "/plan-form",
  EDIT_PLAN: ApiBasePath.PROTOTYPE + "/plan-form/:planId",
  VIEW_PLAN: ApiBasePath.PLAN,

  // SPACE
  ADD_SPACE: ApiBasePath.ZONE + "/space-form",
  EDIT_SPACE: ApiBasePath.ZONE + "/space-form/:spaceId",
  VIEW_SPACE: ApiBasePath.SPACE,

  // ZONE
  ADD_ZONE: ApiBasePath.PLAN + "/zone-form",
  EDIT_ZONE: ApiBasePath.PLAN + "/zone-form/:zoneId",
  VIEW_ZONE: ApiBasePath.ZONE,

  //LAYERS
  ADD_LAYER: ApiBasePath.PLAN + "/spaces/:spaceId/layer-form",
  EDIT_LAYER: ApiBasePath.PLAN + "/spaces/:spaceId/layer-form/:layerId",
  VIEW_LAYER: ApiBasePath.LAYER,
  VIEW_PRODUCT_IN_LAYER: ApiBasePath.LAYER_VARIANT,
  PRODUCT_LIST: ApiBasePath.LAYER + "/product-list",
  PRODUCT_REPLACE: ApiBasePath.LAYER + "/product-list/:layerVariantId",
};
