import getObjectIdSchema from "shared/utils/getObjectIdValidation";
import * as Yup from "yup";

export const addItemWithImageValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  image: getObjectIdSchema().required("Image is required"),
});

export const addItemValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});
