import React from "react";
import { Variant } from "models/Variant/variant.model";
import { EditableColumnsType } from "shared/types/editableColumn.type";
import { formatWithNDecimalPoints } from "shared/utils/formatNumber";
import { Delete, Info } from "shared/icons";
import AddVariantPopover from "./AddVariantPopover";
import { SubCategory } from "models/SubCategory/subCategory.model";
import { useField } from "formik";
import { generateDropdownOptions } from "shared/utils/generateDropdownOptions";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { useLocation } from "context/LocationContext";
import UploadInput from "shared/components/UploadInput";
import UploadedImage from "shared/components/UploadedImage";
import { Space, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import { priceUnitTypes } from "shared/constants/products";

interface VariantColumnProps {
  subCategories: SubCategory[];
}

const getColumns = ({
  subCategories,
}: VariantColumnProps): EditableColumnsType<Variant> => {
  const { productId } = useParams();
  const isRoleVendor =
    JSON.parse(localStorage.getItem("user_role_access") ?? "")?.roleName ===
    "Vendor";
  const isProductCostEditable = !(productId && isRoleVendor);
  const [{ value: variants }, , { setValue }] = useField<Variant[]>("variants");

  const { countries } = useLocation();

  const countryOptions = generateDropdownOptions("name", "id", countries);
  const priceUnitTypeOptions = generateDropdownOptions(
    "label",
    "value",
    priceUnitTypes
  );

  const handleDelete = (type: "image" | "variant", variant: Variant) => {
    if (type === "variant")
      setValue(
        variants?.filter(
          (existingVariant) => existingVariant?.id !== variant?.id
        )
      );
    else {
      const updatedVariant = variants?.map((existingVariant) =>
        existingVariant?.id === variant?.id
          ? set(cloneDeep(existingVariant), "image", undefined)
          : existingVariant
      );

      setValue(updatedVariant);
    }
  };

  return [
    {
      dataIndex: "variantTitle",
      render: (_, variant) => (
        <AddVariantPopover subCategories={subCategories} variant={variant} />
      ),
      title: "item",
      width: 200,
      ellipsis: true,
    },
    {
      dataIndex: "name",
      editable: true,
      width: 190,
      title: "item name",
      render: (name) => name || <span className="text-placeholder">Enter</span>,
      ellipsis: true,
    },
    {
      dataIndex: "image",
      width: 82,
      align: "center",
      className: "multiple-variant__image",
      render: (image, variant, index) =>
        image?.id ? (
          <div className="multiple-variant__image__container">
            <UploadedImage
              expandable
              width="32"
              hideRemoveTextOnOverlay
              src={image?.url}
              onDelete={() => handleDelete("image", variant)}
            />
          </div>
        ) : (
          <Tooltip title="Supported formats: JPG, JPEG & PNG in 1:1 ratio and < 10 MB">
            <UploadInput.Formik
              name={`variants.${index}.image`}
              width={32}
              hideStatusBar
              validateAspectRatio
              aspectRatio={1}
            />
          </Tooltip>
        ),
      title: "item image",
    },
    {
      dataIndex: ["cost", "value"],
      inputType: "decimalNumber",
      editable: isProductCostEditable,
      width: 103,
      render: (cost) => <>{`${formatWithNDecimalPoints(cost, 2)} $`}</>,
      title: () => (
        <Space align="start">
          <span>CONTRACT SELL PRICE</span>
          <Tooltip title="Contract sell price is provided in Canadian dollars">
            <Info className="icon--info" />
          </Tooltip>
        </Space>
      ),
    },
    {
      dataIndex: ["cost", "type"],
      inputType: "dropdown",
      editable: isProductCostEditable,
      render: (_, variant) => {
        const selectedPriceType = priceUnitTypes.find(
          (priceType) => priceType.value === variant?.cost?.type
        );

        return (
          selectedPriceType?.label || (
            <span className="text-placeholder">Select Price Type</span>
          )
        );
      },
      options: () => priceUnitTypeOptions,
      width: 167,
      title: () => (
        <Space align="start">
          <span>PRICE TYPE</span>
          <Tooltip title="Choose the unit of measurement that best fits your product">
            <Info className="icon--info" />
          </Tooltip>
        </Space>
      ),
    },
    {
      dataIndex: "count",
      inputType: "wholeNumber",
      editable: true,
      width: 96,
      render: (count = 0) =>
        count || <span className="text-placeholder">Enter</span>,
      title: () => <span className="text-right">available count</span>,
    },
    {
      dataIndex: "leadTime",
      editable: true,
      inputType: "wholeNumber",
      width: 96,
      title: "max lead time (weeks)",
      render: (val) => val || <span className="text-placeholder">Enter</span>,
    },
    {
      dataIndex: ["location", "country", "id"],
      editable: true,
      inputType: "dropdown",
      title: "stored country",
      render: (_, variant) => {
        const country = countries?.find(
          (country) => country?.id === variant?.location?.country?.id
        );
        return (
          country?.name || (
            <span className="text-placeholder">Search &amp; Select</span>
          )
        );
      },
      options: () => countryOptions,
      width: 167,
    },
    {
      dataIndex: ["location", "province", "id"],
      editable: true,
      inputType: "dropdown",
      title: "stored province / state",
      width: 167,
      render: (_: string, variant) => {
        const province = countries
          ?.find(({ id }) => variant?.location?.country?.id === id)
          ?.provinces?.find(
            (province) => province?.id === variant?.location?.province?.id
          );

        return (
          province?.name || (
            <span className="text-placeholder">Search &amp; Select</span>
          )
        );
      },
      options: (variant) => {
        const country = countries?.find(
          ({ id }) => variant?.location?.country?.id === id
        );
        return generateDropdownOptions("name", "id", country?.provinces);
      },
    },
    ...(variants?.length <= 1
      ? []
      : [
          {
            width: 56,
            render: (_: unknown, variant: Variant) => (
              <Tooltip placement="top" title="Delete">
                <Delete
                  className="icon--delete"
                  onClick={() => handleDelete("variant", variant)}
                />
              </Tooltip>
            ),
          },
        ]),
  ];
};

export default getColumns;
