/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Back } from "shared/icons";
import { ViewDescription } from "shared/icons";
import Input from "shared/components/Input";
import TextArea from "shared/components/TextArea";
import Button from "shared/components/Button";
import { ButtonType } from "enums/buttonType.enum";
import useRedirect from "shared/hooks/useRedirect";
import Dropdown from "shared/components/DropdownField";
import { useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "interceptor/axiosInstance";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { deserialize } from "serializr";
import { QUERY_KEYS } from "shared/constants/queries";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SpaceTypePostData } from "types/spaceTypes";
import Spinner from "shared/components/Spinner";
import { NavigationRoutes } from "routes/routeConstants/appRoutes";
import { useDeleteSpaceType } from "shared/hooks/useSpaceTypes";
import DeleteModal, { DeleteModalProps } from "shared/components/DeleteModal";
import { Delete } from "shared/icons";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  categoryId: Yup.string().required("Category is required"),
  subCategoryId: Yup.string().required("Sub Category is required"),
});

const SpaceTypeForm = () => {
  const { spaceTypeId } = useParams();
  const [searchParams] = useSearchParams();
  const { goBack } = useRedirect();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState<DeleteModalProps>({
    open: false,
    loading: false,
    onCancel: () => {
      return;
    },
    onDelete: () => {
      return;
    },
    subTitle: "",
    title: <></>,
    deleteText: "",
    hideTrash: true,
  });

  const deleteSpaceTypeMutation = useDeleteSpaceType();

  const categoryFromParams = searchParams.get("category");
  const subCategoryFromParams = searchParams.get("subCategory");

  // Fetch space type details if spaceTypeId exists
  const { data: spaceType, isLoading: spaceTypeLoading } = useQuery({
    queryKey: [
      QUERY_KEYS.SPACE_TYPE,
      {
        spaceTypeId,
      },
    ],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `${ApiRoutes.SPACE_TYPES}/${spaceTypeId}`
      );
      return data?.space_type as SpaceTypePostData;
    },
    enabled: !!spaceTypeId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: categories } = useQuery({
    queryKey: [QUERY_KEYS.SPACE_TYPE_CATEGORIES],
    queryFn: async () => {
      const { data } = await axiosInstance.get(ApiRoutes.SPACE_TYPE_CATEGORIES);
      return deserialize(
        SpaceTypeCategory,
        data["space_type_categories"]
      ) as SpaceTypeCategory[];
    },
    refetchOnWindowFocus: false,
  });

  const initialValues = spaceTypeId
    ? {
        title: spaceType?.title || "",
        description: spaceType?.description || "",
        categoryId: spaceType?.category?._id || "",
        subCategoryId: spaceType?.subCategory?._id || "",
      }
    : {
        title: "",
        description: "",
        categoryId: categoryFromParams || "",
        subCategoryId: subCategoryFromParams || "",
      };

  useEffect(() => {
    if (categoryFromParams && !selectedCategory) {
      setSelectedCategory(categoryFromParams);
    }
  }, [categoryFromParams]);

  const mutation = useMutation({
    mutationFn: async (values: typeof initialValues) => {
      const payload = {
        space_type: {
          title: values.title,
          description: values.description,
          category: values.categoryId,
          subCategory: values.subCategoryId,
        },
      };
      const { data } = spaceTypeId
        ? await axiosInstance.put(
            generatePath(ApiRoutes.SPACE_TYPE, { spaceTypeId }),
            payload
          )
        : await axiosInstance.post(ApiRoutes.SPACE_TYPES, payload);
      return data?.space_type as SpaceTypePostData;
    },
    onSuccess: (data) => {
      Notification({
        message: `Space type ${
          spaceTypeId ? "updated" : "created"
        } successfully`,
        type: NotificationTypes.SUCCESS,
      });
      navigate(
        generatePath(NavigationRoutes.VIEW_SPACE_TYPE, { spaceTypeId: data.id })
      );
    },
    onError: (error: any) => {
      Notification({
        message:
          error?.response?.data?.message ||
          `Failed to ${spaceTypeId ? "update" : "create"} space type`,
        type: NotificationTypes.ERROR,
      });
    },
  });

  const handleSubmit = (values: typeof initialValues) => {
    mutation.mutate(values);
  };

  const selectedCategoryData = categories?.find(
    (cat) => cat.id === (selectedCategory || spaceType?.category?._id)
  );

  const handleDelete = async () => {
    if (!spaceTypeId) return;

    await deleteSpaceTypeMutation.mutateAsync(spaceTypeId);
    navigate(NavigationRoutes.SPACE_TYPES);
  };

  if (spaceTypeId && spaceTypeLoading) {
    return <Spinner loading={true} />;
  }

  return (
    <div className="tw-max-w-[75.6rem] tw-mx-auto tw-py-6">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <div>
            <div className="tw-flex tw-flex-col tw-gap-6">
              <div className="tw-flex tw-justify-between tw-items-center">
                <div
                  className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                  onClick={goBack}
                >
                  <Back className="tw-w-4 tw-h-4" />
                  <span>back</span>
                </div>
                <div className="tw-flex tw-items-center tw-gap-4">
                  {spaceTypeId && (
                    <Button
                      type={ButtonType.TEXT}
                      icon={<Delete className="icon-delete" />}
                      className="button--delete"
                      onClick={() => {
                        setModalProps({
                          hideTrash: false,
                          onDelete: () => {
                            handleDelete();
                          },
                          onCancel: () => {
                            setModalProps({ open: false, ...modalProps });
                          },
                          open: true,
                          deleteText: "Delete",
                          subTitle:
                            "This space type will be permanently deleted.",
                          loading: deleteSpaceTypeMutation.isLoading,
                        });
                      }}
                    >
                      Delete Space Type
                    </Button>
                  )}
                  <Button type={ButtonType.DEFAULT} onClick={goBack}>
                    Discard
                  </Button>
                  <Button
                    type={ButtonType.PRIMARY}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(values);
                    }}
                    loading={mutation.isLoading}
                    disabled={!values.categoryId || !values.subCategoryId}
                  >
                    {spaceTypeId ? "Update" : "Create"}
                  </Button>
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-gap-6">
                <Input.Formik
                  name="title"
                  placeholder="Enter Space Type Name"
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setFieldValue("title", trimmedValue);
                  }}
                />

                <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                  <Dropdown.Formik
                    name="categoryId"
                    placeholder="Select Category"
                    options={categories?.map((cat) => ({
                      label: cat.name,
                      value: cat.id,
                    }))}
                    onChange={(value) => {
                      setSelectedCategory(value as string);
                      setFieldValue("categoryId", value);
                      setFieldValue("subCategoryId", "");
                    }}
                    value={values.categoryId}
                  />

                  <Dropdown.Formik
                    name="subCategoryId"
                    placeholder="Select Sub Category"
                    options={selectedCategoryData?.subCategories?.map(
                      (sub) => ({
                        label: sub.name,
                        value: sub.id,
                      })
                    )}
                    disabled={!selectedCategory && !spaceType?.category?._id}
                    value={values.subCategoryId}
                  />
                </div>

                <div className="tw-border tw-border-solid tw-border-[#d9d9d9] tw-rounded-lg tw-p-4">
                  <div className="tw-flex tw-items-center tw-gap-4 tw-mb-4">
                    <ViewDescription className="tw-w-5 tw-h-5" />
                    <span>Space Type Description</span>
                  </div>
                  <TextArea.Formik
                    name="description"
                    rows={10}
                    placeholder="Enter description"
                    className="!tw-border-none !tw-p-0 !tw-shadow-none"
                    onBlur={(e) => {
                      const trimmedValue = e.target.value.trim();
                      setFieldValue("description", trimmedValue);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>

      <DeleteModal {...modalProps} />
    </div>
  );
};

export default SpaceTypeForm;
