import { useState } from "react";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Plan } from "../../models/Plans/plans.model";
import Notification from "shared/components/Notification";
import { NotificationTypes } from "enums/notificationTypes";

const PlanService = () => {
  const [loading, setLoading] = useState(false);

  const [planLoading, setPlanLoading] = useState(false);

  const [plan, setPlan] = useState(new Plan());

  const updatePlanDetails = (newPlan: Plan) => {
    setPlan(newPlan);
  };

  const createPlan = async (prototypeId: string, plan: Plan) => {
    try {
      setPlanLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.PLANS, {
        id: prototypeId,
      });

      const { data } = await axiosInstance.post(ENDPOINT, {
        plan: serialize(Plan, plan),
      });

      const newPlan = deserialize(Plan, data["plan"]);

      setPlan(newPlan);

      Notification({
        message: `Created plan ${newPlan?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      return newPlan;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to create Plan",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setPlanLoading(false);
    }
  };

  const updatePlan = async (prototypeId: string, plan: Plan) => {
    try {
      setPlanLoading(true);

      const ENDPOINT = generatePath(ApiRoutes.PLAN, {
        id: prototypeId,
        planId: String(plan.id),
      });

      const payload = {
        plan: {
          title: plan.title,
          description: plan.description,
          diagram: plan.diagram,
          image: plan.image,
        },
      };

      const { data } = await axiosInstance.put(ENDPOINT, payload);
      const updatedPlan = deserialize(Plan, data["plan"]);

      if (plan.zones?.length) {
        updatedPlan.zones = plan.zones;
      }

      Notification({
        message: `Updated plan ${updatedPlan?.title}`,
        type: NotificationTypes.SUCCESS,
      });

      setPlan(updatedPlan);
      return updatedPlan;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to update Plan",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setPlanLoading(false);
    }
  };

  const showPlan = async (prototypeId: string, planId: string) => {
    try {
      setLoading(true);
      const ENDPOINT = generatePath(ApiRoutes.PLAN, {
        id: prototypeId,
        planId,
      });
      const { data } = await axiosInstance.get(ENDPOINT);

      const plan = deserialize(Plan, data["plan"]);

      setPlan(plan);

      return plan;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to get Plan",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const deletePlan = async (prototypeId: string, planId: string) => {
    try {
      setPlanLoading(true);
      const URL = generatePath(ApiRoutes.PLAN, {
        id: prototypeId,
        planId,
      });
      await axiosInstance.delete(URL);
      setPlan(new Plan());
      Notification({
        message: `Plan deleted`,
        type: NotificationTypes.SUCCESS,
      });
      return true;
    } catch (ex) {
      Notification({
        message: (ex as Error)?.message || "Unable to delete Plan",
        type: NotificationTypes.ERROR,
      });
    } finally {
      setPlanLoading(false);
    }
  };

  return {
    loading,
    plan,
    planLoading,
    showPlan,
    createPlan,
    updatePlan,
    deletePlan,
    updatePlanDetails,
  };
};

export default PlanService;
