import React, { FC } from "react";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { SpaceTypeSubCategory } from "models/SpaceType/spaceTypeSubCategory.model";
import { Skeleton } from "antd";
import { useSearchParams } from "react-router-dom";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LazyLoadedImage from "shared/components/LazyLoadedImage";
// import DefaultImage from "assets/images/prodImg.png"; // Make sure to import your default image
import "./spaceTypesGridView.scss";

const carouselResponsiveWidthConfig: ResponsiveType = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1800 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

interface SpaceTypesGridViewType {
  categories?: SpaceTypeCategory[];
  isLoading: boolean;
}

const SpaceTypesGridView: FC<SpaceTypesGridViewType> = ({
  categories,
  isLoading,
}) => {
  const [, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    // Add smooth scrolling behavior
    document.documentElement.style.scrollBehavior = "smooth";
    return () => {
      document.documentElement.style.scrollBehavior = "auto";
    };
  }, []);

  const handleSubCategoryClick = (
    categoryId: string,
    subCategoryId: string
  ) => {
    setSearchParams({ category: categoryId, subCategory: subCategoryId });
  };

  return (
    <div className="category-container">
      <Skeleton loading={isLoading}>
        {categories?.map((category, idx) => (
          <React.Fragment key={category?.id}>
            {idx > 0 && <div className="category-container__empty-1" />}
            <div className="category-container__empty-2" />
            <div id={category?.id} className="category-card">
              <div className="category-card__header">
                <div className="category-card__header__title">
                  {category?.name}
                </div>
                <div
                  className="category-card__header__view-all"
                  onClick={() =>
                    setSearchParams({ category: category?.id || "" })
                  }
                >
                  View All
                </div>
              </div>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={false}
                centerMode={false}
                containerClass="rmc__container"
                dotListClass="rmc--dot--list"
                draggable
                focusOnSelect={false}
                infinite={false}
                itemClass="rmc__item"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={carouselResponsiveWidthConfig}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                swipeable
              >
                {category?.subCategories?.map(
                  (subCategory: SpaceTypeSubCategory) => (
                    <div
                      key={subCategory?.id}
                      className="subcategory-list__contain__content-list"
                      onClick={() => {
                        if (subCategory?.id && category?.id) {
                          handleSubCategoryClick(category.id, subCategory.id);
                        }
                      }}
                    >
                      <div className="subcategory-list__contain__content-list__content hover-animation-cat">
                        {/* <div className="subcategory-list__contain__content-list__content__image">
                          <LazyLoadedImage
                            src=""
                            alt={subCategory?.name}
                            active={false}
                          />
                        </div> */}
                        <div className="jus">
                          <p className="subcategory-list__contain__content-list__content__title">
                            {subCategory?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Carousel>
            </div>
          </React.Fragment>
        ))}
      </Skeleton>
    </div>
  );
};

export default SpaceTypesGridView;
