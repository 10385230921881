import { NotificationTypes } from "enums/notificationTypes";
import axiosInstance from "interceptor/axiosInstance";
import { SpaceTypeCategory } from "models/SpaceType/spaceTypeCategory.model";
import { SpaceTypeSubCategory } from "models/SpaceType/spaceTypeSubCategory.model";
import { generatePath } from "react-router-dom";
import { ApiRoutes } from "routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import Notification from "shared/components/Notification";

const SpaceTypeService = () => {
  const addSpaceTypeCategory = async (category: SpaceTypeCategory) => {
    try {
      const { data } = await axiosInstance.post(
        ApiRoutes.SPACE_TYPE_CATEGORIES,
        {
          space_type_category: serialize(SpaceTypeCategory, category),
        }
      );
      return deserialize(SpaceTypeCategory, data["space_type_category"]);
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to create Space Type Category",
      });
    }
  };

  const addSpaceTypeSubCategory = async (
    subCategory: SpaceTypeSubCategory,
    categoryId: string
  ) => {
    try {
      const ENDPOINT = generatePath(ApiRoutes.SPACE_TYPE_SUB_CATEGORIES, {
        categoryId,
      });

      const { data } = await axiosInstance.post(ENDPOINT, {
        space_type_sub_category: serialize(SpaceTypeSubCategory, subCategory),
      });
      return deserialize(SpaceTypeSubCategory, data["space_type_sub_category"]);
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to create Space Type Sub Category",
      });
      throw ex;
    }
  };

  const updateSpaceTypeCategory = async (category: SpaceTypeCategory) => {
    try {
      if (!category?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.SPACE_TYPE_CATEGORY, {
        categoryId: category?.id,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        space_type_category: serialize(SpaceTypeCategory, category),
      });
      return deserialize(SpaceTypeCategory, data["space_type_category"]);
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to update Space Type Category",
      });
    }
  };

  const updateSpaceTypeSubCategory = async (
    subCategory: SpaceTypeSubCategory,
    categoryId: string
  ) => {
    try {
      if (!subCategory?.id) return;

      const ENDPOINT = generatePath(ApiRoutes.SPACE_TYPE_SUB_CATEGORY, {
        categoryId,
        subCategoryId: subCategory?.id,
      });

      const { data } = await axiosInstance.put(ENDPOINT, {
        space_type_sub_category: serialize(SpaceTypeSubCategory, subCategory),
      });
      return deserialize(SpaceTypeSubCategory, data["space_type_sub_category"]);
    } catch (ex) {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Unable to update Space Type Sub Category",
      });
    }
  };

  const deleteSpaceTypeCategory = async (categoryId: string) => {
    try {
      await axiosInstance.delete(
        generatePath(ApiRoutes.SPACE_TYPE_CATEGORY, { categoryId })
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteSpaceTypeSubCategory = async (
    subCategoryId: string,
    categoryId: string
  ) => {
    try {
      await axiosInstance.delete(
        generatePath(ApiRoutes.SPACE_TYPE_SUB_CATEGORY, {
          categoryId,
          subCategoryId,
        })
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    addSpaceTypeCategory,
    addSpaceTypeSubCategory,
    updateSpaceTypeCategory,
    updateSpaceTypeSubCategory,
    deleteSpaceTypeCategory,
    deleteSpaceTypeSubCategory,
  };
};

export default SpaceTypeService;
