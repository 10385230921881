import React, { ComponentProps } from "react";
import { ErrorMessage, Field, useField } from "formik";
import { Input as AntInput, InputProps as AntInputProps, Space } from "antd";
import Label from "../Label";
import Error from "../Error";
import { ReactComponent as SearchIcon } from "assets/icons/solid/search.svg";
import { Eye, EyeSlash } from "shared/icons";

import "./input.scss";

export interface InputGenericProps {
  label?: string;
  error?: string;
}
export interface InputProps extends AntInputProps, InputGenericProps {}

export interface InputSearchProps extends InputProps {
  onSearch?: (search: string) => void;
}

interface InputWithFormikProps extends InputProps {
  name: string;
  hideError?: boolean;
}

type InputPasswordProps = InputGenericProps &
  ComponentProps<typeof AntInput.Password>;

type InputPasswordWithFormikProps = InputPasswordProps & { name: string };

const Input = ({ label, error, ...props }: InputProps) => (
  <div className="input">
    <Label label={label} hasError={!!error} />
    <AntInput
      status={error ? "error" : undefined}
      placeholder="Enter"
      {...props}
    />
  </div>
);

const InputPassword = ({ label, error, ...props }: InputPasswordProps) => (
  <div className="input">
    <Label label={label} hasError={!!error} />
    <AntInput.Password
      status={error ? "error" : undefined}
      iconRender={(visible) =>
        visible ? (
          <EyeSlash width="1.2rem" height="1.5rem" />
        ) : (
          <Eye width="1.2rem" height="1.5rem" />
        )
      }
      {...props}
    />
  </div>
);

const InputWithSearch = ({ onSearch, ...props }: InputSearchProps) => {
  const handleSearch: InputProps["onChange"] = (e) =>
    onSearch?.(e.currentTarget.value);

  return (
    <Input
      prefix={<SearchIcon className="search-icon" />}
      placeholder="Search"
      onChange={handleSearch}
      {...props}
    />
  );
};

const InputWithFormik = <T extends string = string>({
  name,
  hideError,
  ...props
}: T extends "password"
  ? InputPasswordWithFormikProps & { hideError?: boolean }
  : InputWithFormikProps & { hideError?: boolean }) => {
  const [{ value }, { error, touched }] = useField(name);

  return (
    <Space direction="vertical">
      <Field
        name={name}
        error={touched && error}
        as={props?.type === "password" ? InputPassword : Input}
        value={value}
        {...props}
      />
      {hideError ? (
        <></>
      ) : (
        <ErrorMessage name={name}>
          {(msg) => <Error message={msg} />}
        </ErrorMessage>
      )}
    </Space>
  );
};

Input.Formik = InputWithFormik;

Input.Search = InputWithSearch;

export default Input;
